<template>
<div class="page-content w-100">
  <!-- start heading -->
  <div class="row">
    <div class="col-sm-12">
      <p class="text-center page-heading">Consultation Desk</p>
    </div>
  </div>
  <!-- end heading -->
  <div class="row px-2">
    <div class="col-md-12 col-xs-12">
      <ul class="nav nav-pills mb-3 nav-justified" id="pills-tab" role="tablist">
        <!-- <li class="nav-item" role="presentation">
          <button class="nav-link text-color active text-20 font-weight-bold" id="pills-schedule-tab" data-bs-toggle="pill" data-bs-target="#pills-schedule" type="button" role="tab" aria-controls="pills-schedule" aria-selected="false">Schedule</button>
        </li> -->
        <!-- <li class="nav-item" role="presentation">
          <button class="nav-link text-color active  font-weight-bold newConsultationText" id="pills-new-tab" data-bs-toggle="pill" data-bs-target="#pills-new" type="button" role="tab" aria-controls="pills-new" aria-selected="false">New consultation</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link text-color  font-weight-bold newConsultationText" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report" type="button" role="tab" aria-controls="pills-report" aria-selected="false">Report</button>
        </li> -->
        <!-- <h4 class="newConsultationTab">New consultation</h4> -->
        <!-- <li class="nav-item" role="presentation">
          <button class="nav-link text-color text-20 font-weight-bold" id="pills-team-tab" data-bs-toggle="pill" data-bs-target="#pills-team" type="button" role="tab" aria-controls="pills-team" aria-selected="false">Team</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link text-color text-20 font-weight-bold" id="pills-search-tab" data-bs-toggle="pill" data-bs-target="#pills-search" type="button" role="tab" aria-controls="pills-search" aria-selected="false">Search</button>
        </li> -->
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <!-- <div class="tab-pane fade show active" id="pills-schedule" role="tabpanel" aria-labelledby="pills-schedule-tab">
          <Schedule accordionCollapse="collapsePatient" accordionId="accordionSchedule"></Schedule>
        </div> -->
        <div class="tab-pane fade show active" id="pills-new" role="tabpanel" aria-labelledby="pills-new-tab">
          <New title="new"></New>
        </div>
        <div class="tab-pane fade" id="pills-report" role="tabpanel" aria-labelledby="pills-report-tab">
          <Report title="report"></Report>
        </div>
        <!-- <div class="tab-pane fade" id="pills-team" role="tabpanel" aria-labelledby="pills-team-tab">
          <Team title="team"></Team>
        </div>
        <div class="tab-pane fade" id="pills-search" role="tabpanel" aria-labelledby="pills-search-tab">
           <Search></Search>
        </div> -->
      </div>
    </div>
  </div>
</div>
<doctorfooter></doctorfooter>
</template>
<script>
import doctorfooter from "../../common/doctor-footer.vue"
// import Team from "./team.vue"
import Report from "./report.vue"
import New from "./new.vue"
// import Search from "./doctorSearch.vue"
// import Schedule from "./doctorSchedule.vue"
export default {
   components:{
    doctorfooter,
    //  Team,
     New,
     Report
    //  Search,
    //  Schedule
   }
}
</script>
<style>
.newConsultationTab{
  padding: 15px;
  margin-bottom: 0px;
}
.newConsultationText{
  font-size: 20px;
}
@media screen and (max-width:370px) {
.newConsultationText{
  font-size: 16px;
}
}
</style>