<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
        <div class="page-content w-100">
            <div class="row g-0">
      <div class="col-sm-12">
        <p class="text-center page-heading">Reports</p>
      </div>
    </div>
    <div class="px-4">
    <div class="row">
        <div class="col-md-12 mx-auto">
            <div class="px-2">
                <div class="row reportHeading">
                    <div class="col-12">
                        <h4 class="">Select a date range to view</h4>
                    </div>
                </div>
                <!-- :max="new Date().toISOString().split('T')[0]" -->
                <div class="row mt-3">
                    <div class="col-12 col-sm-3">
                        <label for="" >From</label>
                        <input type="date" id="datepicker" class="form-control" :min="doctorCreatedDate" :max="maxDate" v-model="startDate" />
                    </div>
                    <div class="col-12 col-sm-3">
                        <label for="">To</label>
                        <input type="date" id="datepicker" class="form-control" :min="startDate" :max="maxDate" v-model="toDate" />
                    </div>
                    <div class="col-12 col-sm-1 mt-4">
                        <button class="btn btn-blue-color text-white" @click="reportDateRange(startDate, toDate)" :disabled="!recordDate">Go</button>
                    </div>
                    <div class="col-12 col-sm-1 mt-4">
                        <button class="btn btn-color text-white" @click="clearBtn()" >Clear</button>
                    </div>
                    
                </div>
                <div class="mt-4">
                    <div class="hr-border"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="showDate">
        
        <div class="col-md-12 mx-auto">
            <div class="px-2">
                <div class="row mt-5">
                    <div class="col-12">
                        <h3 v-for="(item, record) in consultation" :key="record" v-if="consultation.length" >  Consultations on {{ item.consultation_date }}
                            <img class="plusMinusIcon" v-if="item.isClick " src="~@/assets/images/minus.png" @click="handleIcon(item)"
                                />
                            <img class="plusMinusIcon" v-if="!item.isClick"  src="~@/assets/images/plus.png" @click="handleIcon(item)"
                                />
                            <div v-for="doctor in userFamilyData" :key="doctor">

                                <h5 v-if="openDoctor && item.consultation_date === doctor.consultation_date && item.isClick"  >{{ doctor.user.firstName }} {{ doctor.user.lastName }}</h5>
                            </div>
                            </h3>
                            <h2 v-else class="text-center noRecordText">No records found</h2>
                    </div>
                    
                </div>
              
            </div>
        </div>
    </div>
    </div>
</div>
<doctorfooter></doctorfooter>
</template>
<script>
import doctorfooter from "../../common/doctor-footer.vue"
import axios from 'axios';
import moment from 'moment';
export default {
    components: {
    doctorfooter,

    },
    data() {
        return {
            openDoctor: false,
            doctorRecord: [],
            uploadRecord: [],
            doctorInfo: [],
            uploadDataRecords: [],
            familyRecords: [],
            dateFilter: [],
            arrayRecords:[],
            startDate: '',
            toDate: '',
            practiceGroupCount: '',
            showDate: false,
            doctorCreatedDate:"",
            currentDoctorData:[],
            isUploadRecordData:true,
            fullUploadRecordDate:[],
        }
    },
    computed:{

        recordDate(){
            return this.toDate && this.startDate
        },
        maxDate() {
      // Set the maximum date to today's date
      return new Date().toISOString().split('T')[0];
    },
    consultation() {
    const uniqueCreatedAtSet = new Set();
    // Filter out duplicate records based on createdAt and create a new array
    return this.currentDoctorData.filter(data => {
        const createdAt = data?.consultation_date;
        if (!uniqueCreatedAtSet.has(createdAt)) {
            uniqueCreatedAtSet.add(createdAt);
            return true;
        }
        return false;
    });
},
userFamilyData() {
    const uniqueCombinationSet = new Set();
    // Filter out duplicate records based on name and consultation_date, create a new array
    return this.currentDoctorData.filter(data => {
        const key = `${data.user.firstName}_${data.consultation_date}`;
        // Check if the combination is not in the set
        if (!uniqueCombinationSet.has(key)) {
            uniqueCombinationSet.add(key);
            return true;
        }
        // If the combination is in the set, it's a duplicate; return false
        return false;
    });
}

    },
    created: function () {
        let DoctorUserId = localStorage.getItem("id");
      this.DoctorId = JSON.parse(DoctorUserId);
        this.doctorConsultation()
    },
    methods: {

        async reportDateRange(startDate, toDate) {
            this.showDate = true
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.currentDoctorData=[]
            await axios
                .get(`${BASE_API_URL}/upload-records/date?start_date=${startDate}&end_date=${toDate}`)
                .then(async (response) => {
                    this.fullUploadRecordDate=response.data
                    this.fullUploadRecordDate.map((consultation)=>{
                        if(consultation?.doctor_id?.id=== this.DoctorId){
                            consultation.consultation_date=moment(consultation.consultation_date).format('DD-MM-YYYY')
                            consultation={
                                user:consultation.family_id || consultation.user_id,
                                role:consultation.family_id?.role || consultation.user_id?.role,
                                doctor:consultation.doctor_id,
                                consultation_date:consultation.consultation_date,
                                isClick:false
                            }
                            this.currentDoctorData.push(consultation)
                            if(this.currentDoctorData.length){
                               this.isUploadRecordData=true
                            }
                        }
                    })
                })
   
        },
        clearBtn(){
            this.startDate="",
            this.toDate=""
            this.showDate=false,
            this.fullUploadRecordDate=[]
        },
        doctorConsultation() {  
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
                axios
                .get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`)
            .then((response)=>{
                const doctorData= response.data
                this.doctorCreatedDate=moment(doctorData.createdAt).format('YYYY-MM-DD');
            })
        },
        handleIcon(data) {
            this.openDoctor = true
            data.isClick= !data.isClick
        },

    }
}
</script>
<style>
.plusMinusIcon{
cursor: pointer;
width: 20px;
}
.reportHeading{
    margin-top: 2rem;
}
.noRecordText {
    color: red;
}

@media screen and (max-width:576px) {
    .reportHeading{
    margin-top: 1rem;
}
}
@media screen and (max-width:391px) {
    .noRecordText {
        font-size: 19px;
    }
}
</style>