<template>
    <div class="row" style="margin-left:auto;margin-right:auto;">
    <div class="col-md-12 mx-auto p-0 m-0">
        <div class="mb-3 col-3">
            <select class="form-control consultation-select" name="select" id="select" v-model="selected">
        <option v-if="uniqueOptions.length > 1" value="1">Select Clinic</option>
        <option v-for="option in uniqueOptions" :key="option.id" :value="option.id">{{ option.clinic_name }}</option>
    </select>
        </div>
    <span v-if="selected !== '1'">
    <label >Enter phone number to search patient details</label>
    <div class=" searchPatientText mt-2 mb-2">
    <div style="margin-right: 5px;">
    <input type="text" id="mobile" maxlength="10" name="mobile" class="form-control" @input="mobileNumberValidate" v-model="publicPayload.mobile" v-on:keyup.enter="isMobileValid ? listPatient() : null" />
    </div>
    <div class=" searchBtn" id="patient_list">
    <span>
    <button type="button" @click="listPatient()" class="btn btn-blue-color text-white"
    :disabled="!isMobileValid">Search</button>
    </span>

    <!-- </div> -->
    <!-- <div class="col-4 col-lg-4 col-md-4 col-sm-4" > -->
    <span>
    <button type="button" @click="clearPatient()" style="margin-left: 4px;" class="btn btn-color text-white"
    >Clear</button>
    </span>
    </div>
    </div>
    <div class="">
    <div v-if="isNot && !openAddPatient" >
    <h3 class="text-center noRecordText mt-3">No records found</h3>
    </div>
    <div class="hr-border"></div>
    <div>
    <div class="row noRecordfoundMainDiv text-align text-center mt-2" v-if="isNot">
    <div class="mt-4" v-if="!enableResendBtnUser && !otpIsVerified">
    <button class="w-50 bg-color text-white OtpButton" @click="GenerateOTP()" :disabled="!isConsultationDataValidNoCheckbox || !timegreater" :class=" !isConsultationDataValidNoCheckbox ? 'disableBtn': ''">Verify mobile number</button>
    </div>
    <div class=" mt-4" v-if="enableResendBtnUser && !otpIsVerified" >
    <button class="btn btn-color text-white tex-align text-center"  :disabled="!isConsultationDataValidNoCheckbox || !timegreater" @click="resendOTPUser" :class=" !isConsultationDataValidNoCheckbox ? 'disableBtn': ''">Resend OTP</button>
    </div>
    <span v-if="!otpIsVerified && enterOTP">
        <div class="EnterOTPdiv mt-2" >
        <label class="enterOTPLabelText" for="">Enter OTP</label>
        <div class="Otpinputs" style="width: 300px; display: flex;justify-content: center;"   >
        <input v-for="(number,index) in otpNumbersUser" ref="otpInputs" :key="index" class="m-2 text-center form-control rounded" type="text" maxlength="1" 
        v-model="otpNumbersUser[index]" @input="validateInputUser(index)" v-on:keyup.enter="combinedOTPUser ? verityOTPUser() : null" />
        </div>
        </div>
        <div class="mt-2" v-if="timeLeftUser > 0 && openOTP ">
    <p  >Resend OTP in <span id="countdowntimer">{{ timeLeftUser }}</span> Seconds</p>
    </div>  
        <div class="text-align text-center">
        <button class="w-50 btn-blue-color text-white tex-align text-center" :class="!combinedOTPUser ? 'disableBtn': ''" :disabled="!combinedOTPUser" @click="verityOTPUser()">
        Verify
        </button>
        </div>
    </span>
    <div class="addUserImg mb-3"  v-if="createPatient">
    <div class="text-center name-fix">Add patient</div>
    <img class="addPluesIcon"  src="@/assets/images/plus-add.png" @click="patientData()" />
    </div>
    </div>
    </div>
    <div class="curveBoxes" v-if="openAddPatient">
    <div  v-if="openAddPatient">
    <div class="row my-2">
    <div class="col-12 col-md-6 col-lg-6">
    <div class="form-group">
    <label class="">First Name<span class="star">*</span></label>
    <input type="text" id="first_name" name="first_name" class="form-control"
    v-model="publicPayload.firstName" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null" />
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6">
    <div class="form-group">
    <label class="">Last Name<span class="star">*</span></label>
    <input type="text" id="last_name" name="last_name" class="form-control"
    v-model="publicPayload.lastName" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null" />
    </div>
    </div>
    </div>
    <div class="row my-2">
    <div class="col-6">
    <label class="">Age<span class="star">*</span></label>
    <input type="number" id="patient_age" name="patient_age" class="form-control" v-model="publicPayload.age" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null" />
    </div>
    <div class="col-6">
    <label for="" class="">Gender<span class="star">*</span></label>
    <select class="form-control" v-model="publicPayload.gender" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null">
    <option value="1" disabled >Select</option>
    <option value="Male">Male</option>
    <option value="Female">Female</option>
    <option value="Others">Others</option>
    </select>
    </div>
    </div>
    <div class="row my-2">
     <div class=" col-6">
    <label class="sign-up-feilds">Pincode<span class="star">*</span></label>
    <input class="form-control" aria-label="pincode" type="text"  maxlength="6"
    v-model="publicPayload.pincode" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null"  >
    </div>
    </div>
    <div class="row my-3">
    <div class="col-5 col-md-3 col-lg-3">
    <button class="btn btn-blue-color text-white" v-if="!this.openSave" :disabled="!isFormFamilyInvalid" @click="addPatientData"
    >Save</button>
    </div>
    </div>
    <hr class="hr-border" />
    </div>
    <div v-if="patientSupport == true"  >
     <div v-for="(records,index) in upload_Record" :key="records.index">
      <div class="d-flex justify-content-end me-2 ">
            <button type="button" @click="cancelCustom(value)" style="font-size: 10px" id="btn_schedule_add"
              class="btn btn_edit fs-3 p-0">
              <font-awesome-icon :icon="['fas', 'window-close']" />
            </button>
          </div>
    <h5 class="taskmanager-title1 text-align text-center" v-show="date">{{ date }}</h5>
    <div class="row mt-2 " >
    <div class="col-lg-12 ">
        <fieldset class="curve-box" >
          <legend class="subHeadingText">Case sheet</legend>
          <div class="row my-2">
            <div class="col-12 col-md-6 col-lg-12">
              <div >
                <div>

                <p class="mb-0">Patient's chief complaint</p>

                </div>
                <div>
                  <textarea class="doctorWritenPlace" v-model="records.chiefComplaint"></textarea>
                 
                </div>

              </div>
              <div >
                <div >
                <div>
                    <p class="mb-0">Clinical signs and symptoms</p>
                </div>
                <div>
                  <textarea class="doctorWritenPlace" v-model="records.ClinicalSignsSymptoms"></textarea>

                </div>
              </div>
              </div>
              <div >
                <div >
                <div>
                    <p class="mb-0">Diagnosis/ Differential diagnosis</p>
                </div>
                <div>
                  <textarea class="doctorWritenPlace" v-model="records.DiagnosisDifferentialDiagnosis"></textarea>

                </div>
               
              </div>
              </div>
            </div>
          </div>
        </fieldset>

    </div>
    </div>
    <div class="row mb-2">
    <div class="col-md-12 col-12">
    <div id="CaseSheet" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
    </div>
    </div>
    </div>
    </div>
   
    
    <div class="mt-3" v-if="ToggleButton">
    <div class='imageUploadDetails'>
    <div class='row'>
    <div class="col imagesTexts">
    <img class="prescription-image" src="@/assets/images/notes-image.png">
    <p class="prescriptionParaFont">Write prescription on your pad</p>
    </div>
    <div class="col imagesTexts pl-0 pr-0">
    <img class="arrowRightImage" src="@/assets/images/rightArrow.png">
    </div>
    <div class="col imagesTexts">
    <img class="prescription-image" src="@/assets/images/phone-image.png">
    <p class="prescriptionParaFont">Take its photo using yourmobile</p>
    </div>
    <div class="col imagesTexts pl-0 pr-0">
    <img class="arrowRightImage" src="@/assets/images/rightArrow.png">
    </div>
    <div class="col imagesTexts">
    <img class="prescription-image" src="@/assets/images/upload-image.png">
    <p class="prescriptionParaFont">Upload the prescription</p>
    </div>
    </div>
    </div>
    </div>
    <div class="row mt-2">
    <div class="col-lg-4 col-md-5 col-sm-6 col-10 mb-0">
    <label>Upload prescription</label>
    </div>
    <div class="col-lg-8 col-md-7 col-sm-6 col-2 question-icon mb-0" @click="toggle()">
    <font-awesome-icon :icon="['fas', 'question-circle']"
    style="font-size: 25px;" />
    </div>
    </div>

    
    
    <div class="row">
    <div class="col-lg-4 col-md-5 col-sm-6 col-10">
    <input type="file" class="form-control" @change="fileSelect($event,index)">
    </div>

  
    <div class="col-lg-8 col-md-7 col-sm-6 col-2">
        <span class="text-align text-center plus-icon" style="font-size:30px"  >
            <font-awesome-icon  @click="isShowAddConsult()" :class="[!checkConsultationAreEntered ? 'disableCreateBtn' : '']"  :icon='["fas", "plus-circle"]' />
        </span>
    </div>
    </div> 
    <div class="row mt-2">
    <label class="" for="date">Next consultation date<span class="star">*</span></label>
    <div class="col-lg-4 col-md-5 col-sm-6 col-10">
    <input type="date" id="datepicker" :min="new Date().toISOString().split('T')[0]"
    class="form-control" v-model="records.nextConsultation_date" />
    </div>
    </div>
    <div class="col-12 col-lg-4 mt-3 " v-if ="hideCreateFootButton">
                <button class="btn btn-blue-color text-white" @click="createFootwearData()">Create footwear
                  order</button>
              </div>

              <div class="col-sm-12 mt-2" v-if="createFootwear">
                <div class="row">
                  <div class="col-sm-12">
                    <p class="text-center page-heading">Footwear order form</p>
                  </div>
                </div>

                <div class="row mt-3 ">
                  <div class="col-md-4 col-lg-4"></div>
                  <div class="col-md-3 col-lg-3  ">
                    <fieldset class="curve-box ">
                      <legend class="subHeadingText">Physical info <span class="star">*</span></legend>

                      <div class="row d-flex justify-content-center ">


                        <div class="col-lg-12 col-8">
                          <div class="d-flex justify-content-evenly">
                            <label class="" style="margin-top: 4px">Height</label>

                            <div class="d-flex">
                              <input type="text  " style="width: 60px;margin-left: 7px" class="doctorWritenPlace"
                                v-model="height">
                                <label style="margin: 4px 5px 0px 5px ">cm</label>

                            </div>

                          </div>
                        </div>
                        <div class="col-lg-12 col-8 mt-3">
                          <div class="d-flex  justify-content-evenly ">
                            <label class="" style="margin-top: 4px">Weight</label>

                            <div class="d-flex">
                              <input type="text" style="width: 60px;" class="doctorWritenPlace" v-model="weight">
                              <label style="margin: 4px 5px 0px 5px ">kg</label>

                            </div>

                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col-md-3 col-lg-4"></div>
                </div>

                <div class="row ">
                  <div class="col-md-3"></div>
                  <div class="col-md-3 col-12">
                    <fieldset class="curve-box left-right">
                      <legend class="subHeadingText">Foot arch <span class="star">*</span></legend>

                      <div class="row foot-joint  ">
                        <div class="col-6 d-flex align-items-center  ">
                          <label>Left foot</label>
                        </div>
                        <div class="col-6  my-1">
                          <select class="form-control foot-arch" style="cursor: pointer;" v-model="footArch.left">
                            <option value="high">High</option>
                            <option value="normal">Normal</option>
                            <option value="low">Low</option>
                          </select>
                        </div>
                        <div class="col-6 d-flex align-items-center ">
                          <label>Right foot</label>
                        </div>
                        <div class="col-6">
                          <select class="form-control" style="cursor: pointer;" v-model="footArch.right">
                            <option value="high">High</option>
                            <option value="normal">Normal</option>
                            <option value="low">Low</option>
                          </select>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <!-- Joint Mobility Section -->
                  <div class="col-md-3 col-12 ms-1">
                    <fieldset class="curve-box left-right">
                      <legend class="subHeadingText">Joint mobility <span class="star">*</span></legend>

                      <div class="row foot-joint">
                        <div class="col-6 d-flex align-items-center ">
                          <label>Left foot</label>
                        </div>
                        <div class="col-6 mb-1">
                          <select class="form-control" style="cursor: pointer;" v-model="jointMobility.left">
                            <option value="normal">Normal</option>
                            <option value="hyper">Hyper</option>
                            <option value="stiff">Stiff</option>
                          </select>
                        </div>
                        <div class="col-6 d-flex align-items-center ">
                          <label>Right foot</label>
                        </div>
                        <div class="col-6">
                          <select class="form-control" style="cursor: pointer;" v-model="jointMobility.right">
                            <option value="normal">Normal</option>
                            <option value="hyper">Hyper</option>
                            <option value="stiff">Stiff</option>
                          </select>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div class="col-md-3"></div>
                </div>

                <div class="row my-12">
                  <div class="col-1 col-lg-3"></div>
                  <div class="col-12 col-lg-6 ">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Foot measurements <span class="star">*</span></legend>

                      <p>Input all measurements in cm. To view measurement guideline.click the parameter.</p>

                      <table class="foot-measurement">
                        <thead>
                          <tr class=" ">
                            <th class="text-center">Parameter</th>
                            <th class="text-center">Left </th>
                            <th class="text-center">Right </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(label, key) in footMeasurement" :key="key">
                            <td> <span class="" style="cursor: pointer; " data-bs-toggle="modal"
                                data-bs-target="#exampleModal" @click="showModal(key)">
                                {{ key }}
                              </span>
                              <div class="modal fade" id="exampleModal" tabindex="-1"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                  <div class="modal-content ">
                                    <div class="modal-header  bg-color">
                                      <h5 class="modal-title text-white" id="exampleModalLabel">{{ selectedMeasurement.key }}</h5>
                                      <button type="button" class="btn-close text-white" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body  m-4" style="border: 2px solid #00979e;">
                                      <img width="100%" :src="selectedMeasurement.image" alt="Measurement image" />
                                    </div>
                                   
                                    <div>
                                      <p class="text-center mx-4">{{ selectedMeasurement.text }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="text-center">
                                <input class="foot-input" type="number" maxlength="2"
                                  v-model="footMeasurement[key].left" />

                              </div>
                            </td>
                            <td>
                              <div class="text-center ">
                                <input class="foot-input" type="number" maxlength="2"
                                  v-model="footMeasurement[key].right" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </div>
                  <div class="col-1 col-lg-3"></div>
                </div>
                <div class="row ">
                  <div class="col-12 col-lg-2  col-md-6"></div>

                  <div class="col-12 col-lg-8  col-md-6">
                    <fieldset class="curve-box mt-2">
                      <legend class="subHeadingText">Anatomical location of the pathology/ symptom</legend>

                      <leftFootPad @image-uploaded="handleLeftImageUpload"></leftFootPad>
                    <rightFootPad @image-uploaded="handleRightImageUpload"></rightFootPad>

                    </fieldset>


                  </div>
                  <div class="col-12 col-lg-2  col-md-6"></div>
                </div>

               
                <div class="row my-12">
                  <div class="col-1 col-lg-3"></div>
                  <div class="col-12 col-lg-6 ">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Areas to offload</legend>

                      <table class="offload-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th class="foot-column text-center">Left </th>
                            <th class="foot-column  text-center">Right </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(label, key) in areasToOffload" :key="key">
                            <td>{{ key }}</td>
                            <td>
                              <div class="text-center">
                                <input type="checkbox" :checked="areasToOffload[key].left === 'Yes'"
                                  @change="updateAreaToOffload(key, 'left', $event)" />

                              </div>
                            </td>
                            <td>
                              <div class="text-center">
                                <input type="checkbox" :checked="areasToOffload[key].right === 'Yes'"
                                  @change="updateAreaToOffload(key, 'right', $event)" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </div>
                  <div class="col-1 col-lg-3"></div>
                </div>



                <div class="row my-12">
                  <div class="col-1 col-lg-3"></div>
                  <div class="col-12 col-lg-6 ">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Offloading methods</legend>
                      <table class="offload-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th class="foot-column  text-center">Left foot</th>
                            <th class="foot-column  text-center ">Right foot</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(label, key) in Offloading_methods" :key="key">
                            <td>{{ key }}</td>
                            <td>
                              <div class="text-center">
                                <input type="checkbox" :checked="Offloading_methods[key].left === 'Yes'"
                                  @change="updateOffloadingMethods(key, 'left', $event)" />
                              </div>
                            </td>
                            <td>
                              <div class="text-center">
                                <input type="checkbox" :checked="Offloading_methods[key].right === 'Yes'"
                                  @change="updateOffloadingMethods(key, 'right', $event)" />
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </div>

                  <div class="col-1 col-lg-3 "></div>
                </div>

                <div class="row my-12">
                  <div class="row mt-2">
                    <div class="col-lg-3"></div>
                    <div class="col-12 col-lg-6">
                      <p class="mb-0">Offloading - Recommendations</p>
                      <p class="mb-0">(like a rocker, ankle support, or long Velcro straps,
                        etc.)</p>
                    </div>
                    <div class="col-lg-3"></div>


                  </div>
                  <div class="row my-3">
                    <div class="col-md-12 col-12">
                      <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <form class="caseSheetTextAreas">
                            <textarea class="doctorWritenPlace" v-model="Offloading_Recommendations"></textarea>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-1 "></div>


                <div class="row my-12">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12 ">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Footwear <span class="star">*</span></legend>

                      <div class="row 12 " v-if="gender === 'Male'">
                        <div class="col-1 col-lg-3"></div>
                        <div class="col-4 col-lg-6  ">
                          <label for="modelSelect" class="form-label">Size (UK)</label>
                          <select v-model="selectedSize"   class="form-control consultation-select-inventory ">
                                <option disabled value="">Select size</option>
                                  <option value= "5">5</option>
                                  <option value= "5.5">5.5</option>
                                  <option value= "6">6</option>
                                  <option value= "6.5">6.5</option>
                                  <option value= "7">7</option>
                                  <option value= "7.5">7.5</option>
                                  <option value= "8">8</option>
                                  <option value= "8.5">8.5</option>
                                  <option value= "9">9</option>
                                  <option value= "9.5">9.5</option>
                                  <option value= "10">10</option>
                                  <option value= "10.5">10.5</option>
                                  <option value= "11">11</option>
                                  <option value= "11.5">11.5</option>
                                  <option value= "12.5">12.5</option>
                                  <option value= "13.5">13.5</option>
                                  <option value= "14.5">14.5</option>
                                  <option value= "15.5">15.5</option>
                            </select>
                        </div>
                        <div class="col-4 col-lg-3"></div>

                      </div>

                       <div class="row 12 " v-if="gender === 'Female'" >
                        <div class="col-1 col-lg-3"></div>
                        <div class="col-4 col-lg-6">
                          <label for="modelSelect" class="form-label">Size (UK)</label>
                          <select v-model="selectedSize" class="form-control consultation-select-inventory ">
                                <option disabled value="">Select size</option>
                                  <option value= "2">2</option>
                                  <option value= "2.5">2.5</option>
                                  <option value= "3">3</option>
                                  <option value= "3.5">3.5</option>
                                  <option value= "4">4</option>
                                  <option value= "4.5">4.5</option>
                                  <option value= "5">5</option>
                                  <option value= "5.5">5.5</option>
                                  <option value= "6">6</option>
                                  <option value= "6.5">6.5</option>
                                  <option value= "7">7</option>
                                  <option value= "7.5">7.5</option>
                                  <option value= "8">8</option>
                                  <option value= "8.5">8.5</option>
                                  <option value= "9">9</option>
                                  <option value= "9.5">9.5</option>
                                  <option value= "10">10</option>
                                  <option value= "10.5">10.5</option>
                            </select>
                        </div>
                        <div class="col-4 col-lg-3"></div>

                      </div> 

                       <div class="row 12 " v-if="gender === 'Others'" >
                        <div class="col-1 col-lg-3"></div>
                        <div class="col-4 col-lg-6">
                          <label for="modelSelect" class="form-label">Size (UK)</label>
                          <select v-model="selectedSize" class="form-control consultation-select-inventory ">
                                <option disabled value="">Select size</option>
                                  <option value= "2">2</option>
                                  <option value= "2.5">2.5</option>
                                  <option value= "3">3</option>
                                  <option value= "3.5">3.5</option>
                                  <option value= "4">4</option>
                                  <option value= "4.5">4.5</option>
                                  <option value= "5">5</option>
                                  <option value= "5.5">5.5</option>
                                  <option value= "6">6</option>
                                  <option value= "6.5">6.5</option>
                                  <option value= "7">7</option>
                                  <option value= "7.5">7.5</option>
                                  <option value= "8">8</option>
                                  <option value= "8.5">8.5</option>
                                  <option value= "9">9</option>
                                  <option value= "9.5">9.5</option>
                                  <option value= "10">10</option>
                                  <option value= "10.5">10.5</option>
                                  <option value= "11">11</option>
                                  <option value= "11.5">11.5</option>
                                  <option value= "12.5">12.5</option>
                                  <option value= "13.5">13.5</option>
                                  <option value= "14.5">14.5</option>
                                  <option value= "15.5">15.5</option>
                                 


                            </select>

                        </div>
                        <div class="col-4 col-lg-3"></div>

                      </div> 

                      <div class="row my-12 ">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-8 col-12 ">
                          <label for="modelSelect" class="form-label ms-4">Model</label>
                          <div class="d-flex mt-2 flex-wrap justify-content-center ">
                            <div class="col-lg-5 col-5 d-flex align-items-center justify-content-between ms-2 mb-2"
                              v-for="(item, index) in filteredModels" :key="index">
                              <img :src="item.images[0]" @click="updateImagesPreview(item)"
                                :class="{ 'border-green': selectedModelId === item.id }" style="cursor: pointer;" />

                            </div>
                          </div>
                        </div>
                        <div class="col-2"></div>
                      </div>

                      <div class="row mt-1 mb-3" v-if="imagesPreview.length">

                        <div class="">
                          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner text-align text-center">
                              <div v-for="(image, index) in imagesPreview" :key="index"
                                :class="['carousel-item', { active: index === currentIndex }]">
                                <img :src="image" class="img-carousel-public">
                              </div>
                            </div>
                            <button class="carousel-control-prev" type="button" @click="prevImage"
                              :disabled="currentIndex === 0">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Previous</span>
                            </button>

                            <button class="carousel-control-next" type="button" @click="nextImage"
                              :disabled="currentIndex === imagesPreview.length - 1">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Next</span>
                            </button>


                            <p class="text-center mt-1">Model selected : {{ this.getModelId.model_no }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="row my-12" v-if="imagesPreview.length">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-8 col-12 ">
                          <label for="modelSelect" class="form-label ms-5 mt-0">Colour</label>
                          <div class="d-flex flex-wrap justify-content-center h-100">
                            <div class="col-lg-2 col-2  d-flex align-items-center justify-content-between ms-2 mb-2"
                              v-for="(item, index) in filteredColors" :key="index">
                              <div>
                                <img :class="{ 'border-green': selecrtedColourId === item.id }" height="50px"
                                  :src="item.images[0]" @click="updateImagesColour(item)" alt="Color Image"
                                  style="cursor: pointer;" />

                                <p>{{ item.colour_name }}</p>



                              </div>


                            </div>

                          </div>

                        </div>
                        <p v-if="selecrtedColourId" class="text-center">Colour selected : {{ this.colourName }}</p>

                        <div class="col-lg-2"></div>
                      </div>


                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>

                <div class="row mt-3">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12 ">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Upload files <span class="star">*</span></legend>
                      <div class="row my-2 ">
                        <div class="col-lg-1 col-1 "></div>
                        <div class="col-5 col-lg-6">

                          <button class="btn btn-blue-color text-white" @click="selectFile('ppsPdfFile')">PPS PDF
                          </button>
                          <input type="file" id="ppsPdfFile" style="display: none;"
                            @change="handleFileChange($event, 'ppsPdfFile')" />
                          <span v-if="uploadedFiles.ppsPdfFile" class="text-success">Uploaded</span>
                          <br>
                          <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->


                        </div>
                        <div class="col-lg-1 col-1  "></div>

                        <div class="col-5 col-lg-4 ">

                          <button class="btn btn-blue-color text-white" @click="selectFile('ppsVideoFile')">PPS
                            video</button>
                          <input type="file" id="ppsVideoFile" style="display: none;"
                            @change="handleFileChange($event, 'ppsVideoFile')" />
                          <span v-if="uploadedFiles.ppsVideoFile" class="text-success">Uploaded</span>
                          <br>
                          <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->
                        </div>

                      </div>
                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>

                <div class="row my-12">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12 ">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Gait videos <span class="star">*</span></legend>
                      <div class="row pb-3 ">
                        <div class="col-lg-2 col-1"></div>
                        <div class="col-3 col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 ">
                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('anteriorVideoFile')">Anterior</button>
                            <input type="file" id="anteriorVideoFile" style="display: none;"
                              @change="handleFileChange($event, 'anteriorVideoFile')" />
                            <span v-if="uploadedFiles.anteriorVideoFile" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->
                          </div>
                        </div>
                        <div class="col-3 col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 ">
                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('posteriorVideoFile')">Posterior</button>
                            <input type="file" id="posteriorVideoFile" style="display: none;"
                              @change="handleFileChange($event, 'posteriorVideoFile')" />
                            <span v-if="uploadedFiles.posteriorVideoFile" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->
                          </div>
                        </div>
                        <div class="col-3 col-lg-3 ">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 ">
                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('bilateralVideoFile')">Bilateral</button>
                            <input type="file" id="bilateralVideoFile" style="display: none;"
                              @change="handleFileChange($event, 'bilateralVideoFile')" />
                            <span v-if="uploadedFiles.bilateralVideoFile" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->
                          </div>
                        </div>
                        <div class="col-lg-2 col-1"></div>

                      </div>
                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>


                <!-- Foot photographs section -->
                <div class="row my-12">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12 ">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Foot photographs <span class="star">*</span></legend>
                      <div class="row ">
                        <div class=" col-lg-2"></div>
                        <div class="col-4 col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12">
                            <h5>Left</h5>
                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('leftLateralPhoto')">Lateral</button>
                            <input type="file" id="leftLateralPhoto" style="display: none;"
                              @change="handleFileChange($event, 'leftLateralPhoto')" />
                            <span v-if="uploadedFiles.leftLateralPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('leftMedialPhoto')">Medial</button>
                            <input type="file" id="leftMedialPhoto" style="display: none;"
                              @change="handleFileChange($event, 'leftMedialPhoto')" />
                            <span v-if="uploadedFiles.leftMedialPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                        <div class="col-4  col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 ">
                            <h5>Right</h5>
                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('rightLateralPhoto')">Lateral</button>
                            <input type="file" id="rightLateralPhoto" style="display: none;"
                              @change="handleFileChange($event, 'rightLateralPhoto')" />
                            <span v-if="uploadedFiles.rightLateralPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('rightMedialPhoto')">Medial</button>
                            <input type="file" id="rightMedialPhoto" style="display: none;"
                              @change="handleFileChange($event, 'rightMedialPhoto')" />
                            <span v-if="uploadedFiles.rightMedialPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                        <div class="col-4  col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12">
                            <h5>B/L</h5>
                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('plantarPhoto')">Plantar</button>
                            <input type="file" id="plantarPhoto" style="display: none;"
                              @change="handleFileChange($event, 'plantarPhoto')" />
                            <span v-if="uploadedFiles.plantarPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('dorsalPhoto')">Dorsal</button>
                            <input type="file" id="dorsalPhoto" style="display: none;"
                              @change="handleFileChange($event, 'dorsalPhoto')" />
                            <span v-if="uploadedFiles.dorsalPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('anteriorPhoto')">Anterior</button>
                            <input type="file" id="anteriorPhoto" style="display: none;"
                              @change="handleFileChange($event, 'anteriorPhoto')" />
                            <span v-if="uploadedFiles.anteriorPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('posteriorPhoto')">Posterior</button>
                            <input type="file" id="posteriorPhoto" style="display: none;"
                              @change="handleFileChange($event, 'posteriorPhoto')" />
                            <span v-if="uploadedFiles.posteriorPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>

                <!-- Foot sketch images section -->
                <div class="row ">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12 ">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Foot sketch images <span class="star">*</span></legend>
                      <div class="row ">
                        <div class="col-lg-3 col-2"></div>
                        <div class="col-5  col-lg-4">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 pb-3">
                            <button class="btn btn-blue-color text-white" @click="selectFile('leftFootSketch')">Left
                              foot</button>
                            <input type="file" id="leftFootSketch" style="display: none;"
                              @change="handleFileChange($event, 'leftFootSketch')" />
                            <span v-if="uploadedFiles.leftFootSketch" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                        <div class="col-5   col-lg-4">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 ">
                            <button class="btn btn-blue-color text-white" @click="selectFile('rightFootSketch')">Right
                              foot</button>
                            <input type="file" id="rightFootSketch" style="display: none;"
                              @change="handleFileChange($event, 'rightFootSketch')" />
                            <span v-if="uploadedFiles.rightFootSketch" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                        <div class="col-lg-2"></div>

                      </div>
                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>
              </div>
  
    <div class="hr-border mt-3"></div>
</div> 

<div class="col-lg-12 col-md-7 col-sm-7 col-12  my-3 text-center" v-if="otpIsVerified && openOTP">
<button  class="btn record btn-blue-color text-white" :disabled="!isConsultationDataValid" @click=" saveConsultation()" style="border-radius: 10px;">Save record</button>
</div>
    </div>
    </div>
    </div>
</span>
</div>

    </div>
    <doctorRecordsView v-if="issubmit " @clearAll="clearAll()" :mobileData="userPayload" :isUserCreated="isUserCreated" :newPublic="newPublic" :selected = "selected"  ></doctorRecordsView>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import doctorRecordsView from '../../user/records/doctorRecordsView.vue'
import leftFootPad from '../../user/records/leftFoot.vue'
import rightFootPad from '../../user/records/rightFoot.vue'
import f0 from '../../../assets/images/f0.jpg'
import f1 from '../../../assets/images/f1.jpg'
import f2 from '../../../assets/images/f2.jpg'
import f3 from '../../../assets/images/f3.jpg'
import f4 from '../../../assets/images/f4.jpg'
import f5 from '../../../assets/images/f5.jpg'
import f6 from '../../../assets/images/f6.jpg'
import f7 from '../../../assets/images/f7.jpg'
import f8 from '../../../assets/images/f8.jpg'
import f9 from '../../../assets/images/f9.jpg'
import f10 from '../../../assets/images/f10.jpg'
import f11 from '../../../assets/images/f11.jpg'

import Swal from "sweetalert2/dist/sweetalert2.js";


export default {
components: {
    doctorRecordsView,
    leftFootPad,
    rightFootPad
    
},
data() {
    return {
getDisorderArray:[],
hideCreateFootButton:true,
userSelected:"",
colourData: [],
filteredColors:[],
addPatient: false,
pastPatientRecord: false,
addSchedule: false,
previousConsultation: false,
uploadedLeftImage: null,
getColourId:null,
selecrtedModelId: null,
selecrtedColourId: null,
selectedMeasurement: {
        key: '',
        text: '',
        image: ''
      },
patient: 0,
currentIndex: 0,
colourName: "",

imagesPreview: [],

upload_Record: [{
chiefComplaint: "",
ClinicalSignsSymptoms: "",
DiagnosisDifferentialDiagnosis: "",
caseSheet: "",
nextConsultation_date: "",
uploadImage: "",}],
issubmit: false,

isNot: false,
openSave: false,
createFootwear: false,
uploadedFiles: {
        ppsPdfFile: false,
        ppsVideoFile: false,
        anteriorVideoFile: false,
        posteriorVideoFile: false,
        bilateralVideoFile: false,
        leftLateralPhoto: false,
        leftMedialPhoto: false,
        rightLateralPhoto: false,
        rightMedialPhoto: false,
        plantarPhoto: false,
        dorsalPhoto: false,
        anteriorPhoto: false,
        posteriorPhoto: false,
        leftFootSketch: false,
        rightFootSketch: false,
      },

loginMobile: "",
mobile: "",
email: "",
openAddPatient: false,
date: '',
fulldate: '',
patientSupport: false,
openOTP: false,
ToggleButton: false,
mobileData: [],
mobileArray: [],
userPayload:{},
publicPayload:{
    age:"",
    gender: '1',
},
isUserCreated:false,
newPublic:null,
gender:"",
selectedSize:'',
userRecordsData: {},

doctorPayload:{},
publicDoctorCommunityData:[],
uploadImage: null,
otpNumbersUser: ['', '', '', ''],
otpIsVerified:false,
timeLeftUser: 30,
timerUser: null,
enableResendBtnUser:false,
createPatient:false,
enterOTP:false,                           
doctorName:"",
clinicId:"",
clinicData:"",
adminCutomerId:"",
footArch: {
        left: '',
        right: '',
      },
      jointMobility: {
        left: '',
        right: '',
      },
      areasToOffload: {
        Hallux: { left: 'No', right: 'No' },
        '2nd-5th toes': { left: 'No', right: 'No' },
        '1st MPJ': { left: 'No', right: 'No' },
        '2nd MPJ': { left: 'No', right: 'No' },
        '3rd MPJ': { left: 'No', right: 'No' },
        '4th MPJ': { left: 'No', right: 'No' },
        '5th MPJ': { left: 'No', right: 'No' },
        'Lateral Midfoot': { left: 'No', right: 'No' },
        'Medial Midfoot': { left: 'No', right: 'No' },
        'Central Midfoot': { left: 'No', right: 'No' },
        'Complete Midfoot': { left: 'No', right: 'No' },
        'Complete Heel': { left: 'No', right: 'No' },
        'Posterior Heel': { left: 'No', right: 'No' },
        'None_Dont Know': { left: 'No', right: 'No' }
      },
      footMeasurement: {
     'Full Length of the foot': { left: '', right: '', text: 'Measure from the most distal part of the toe to the heel.', image: f0 },
        'Length till 1th MPJ': { left: '', right: '', text: 'Measure from the tip of the toe to the 1st metatarsophalangeal joint.', image: f1 },
        'Length of the Foot till 5th MPJ ': { left: '', right: '', text: 'Measure from the tip of the toe to the 5th metatarsophalangeal joint.', image: f2 },
        'Width of the foot from the 1th - 5th MPJ': { left: '', right: '', text: 'Measure the width of the foot at the level of the 1st to 5th MPJ.', image: f3 },
        'Heel Width': { left: '', right: '', text: 'Measure the width of the heel at its widest point.', image: f4 },
        'Arch Height ': { left: '', right: '', text: 'Measure the height of the arch from the floor to the apex of the arch.', image: f5 },
        'Medial Malleolus Height': { left: '', right: '', text: 'Measure the height from the floor to the tip of the medial malleolus.', image: f6 },
        'Lateral Malleolus Height': { left: '', right: '', text: 'Measure the height from the floor to the tip of the lateral malleolus.', image: f7 },
        'Limb Length (Apparent)': { left: '', right: '', text: 'Measure the apparent limb length from the floor to the most distal part of the limb.', image: f8 },
        'Limb Length (True)': { left: '', right: '', text: 'Measure the true limb length from the anterior superior iliac spine to the medial malleolus.', image: f9 },
        'In step Circumference': { left: '', right: '', text: 'Measure the circumference around the instep at the talonavicular joint.', image: f10 },
        'Heel Diagonal': { left: '', right: '', text: 'Measure the diagonal distance across the heel.', image: f11 },
        'Toe Height': { left: '', right: '', text: 'Measure the height of the toes from the floor.', image: f11 },



      },

      Offloading_methods: {
        Scooping: { left: 'No', right: 'No' },
        'Reverse Scooping': { left: 'No', right: 'No' },
        Redsitribution: { left: 'No', right: 'No' },
        'Rocker Outersole': { left: 'No', right: 'No' },
        'Wedge Outersole': { left: 'No', right: 'No' },
        Other: { left: 'No', right: 'No' },
        FS_Orthotist_decide: { left: 'No', right: 'No' }
      },

      files: {
        ppsPdfFile: null,
        ppsVideoFile: null,
        anteriorVideoFile: null,
        posteriorVideoFile: null,
        bilateralVideoFile: null,
        leftLateralPhoto: null,
        leftMedialPhoto: null,
        rightLateralPhoto: null,
        rightMedialPhoto: null,
        plantarPhoto: null,
        dorsalPhoto: null,
        anteriorPhoto: null,
        posteriorPhoto: null,
        leftFootSketch: null,
        rightFootSketch: null,
      },
timegreater:true,
selected: '1',
clinicConsult:[],
homeConsult:[],
getMobile:"",
getUploadData:""
    }
},

mounted() {

if ("vue-drawing-canvas" in window.localStorage) {
  this.initialImage = JSON.parse(
    window.localStorage.getItem("vue-drawing-canvas")
  );
}
this.getInventoryColor()


},
created: async function () {
    let DoctorUserId = localStorage.getItem("id");


  this.DoctorId = JSON.parse(DoctorUserId);

  if(this.DoctorId){
          await this.fetchDoctorClinicAndInventory();
                                     
     } 
    this.date = this.printDate();
   await this.getDoctor()
     if (this.doctorPayload && this.doctorPayload.clinic_hcp_id) {
        await this.getDataConsultation(); 
    } else {
        console.error("Doctor data not fully loaded or missing clinic_hcp_id");
    }

    await this.getInventoryColor() 
    await this.getPatientDesk()


},

computed: {
  filteredModels() {
      if (this.userRecordsData?.gender === 'Male') {
        return this.inventoryData.filter(item => item.model_no.startsWith('M'));
      } else if (this.userRecordsData?.gender === 'Female') {
        return this.inventoryData.filter(item => item.model_no.startsWith('L'));
      } 
        else {
        return this.inventoryData;
      }
    },
  selectedColorImage() {
    const selectedColor = this.colourData.find(item => item.id === this.userSelected);
    return selectedColor ? selectedColor.images[0] : null; 
  },
    combinedOTPUser() {
        if (this.otpNumbersUser.some(input => !/^\d$/.test(input))) {
    return '';
  }
  return this.otpNumbersUser.join('');
},
    isMobileValid() {
        const mobilevalidation = /^[6-9]\d{9}$/;
        return mobilevalidation.test(this.publicPayload.mobile);
    },
    isFormFamilyInvalid() {
const nameRegex = /^[A-Za-z.\s]+$/;
const pincodeValidation = /^[1-9](\s*\d){5}$/;

return (
    nameRegex.test(this.publicPayload.firstName) &&
    nameRegex.test(this.publicPayload.lastName)  &&
    this.publicPayload.age >= 18 && this.publicPayload.age <= 999  && 
    this.publicPayload.gender !== "1"  &&
    this.publicPayload?.gender .trim() !== "" &&
    pincodeValidation.test(this.publicPayload.pincode)
);
},
checkConsultationAreEntered(){
if (this.upload_Record.length === 0) {
return false; // No records to validate
}
for (let i = 0; i < this.upload_Record.length; i++) {
const record = this.upload_Record[i];
if (
  !record.caseSheet ||
  !record.nextConsultation_date 
) {
  return false; 
}
}
return true; 
},
isConsultationDataValid() {
if (this.upload_Record.length === 0) {
return false; // No records to validate
}

for (let i = 0; i < this.upload_Record.length; i++) {
        const record = this.upload_Record[i];
        if (
          !record.chiefComplaint || !record.ClinicalSignsSymptoms || !record.DiagnosisDifferentialDiagnosis ||
          !record.nextConsultation_date
        ) {
          return false;
        }
      }

      return true;
// const mobileValidation = /^[6-9]\d{9}$/;
// const nameRegex = /^[A-Za-z.\s]+$/;
// const pincodeValidation = /^[1-9](\s*\d){5}$/;

// return (
// nameRegex.test(this.publicPayload.firstName) &&
// nameRegex.test(this.publicPayload.lastName) &&
// this.publicPayload.age >= 18 && this.publicPayload.age <= 999 &&
// this.publicPayload.gender !== "1" &&
// this.publicPayload?.gender.trim() !== "" &&
// pincodeValidation.test(this.publicPayload.pincode) &&
// mobileValidation.test(this.publicPayload.mobile) &&
// this.upload_Record.every(record =>
//   record.caseSheet !== undefined &&
//   record.caseSheet !== '' &&
//   record.nextConsultation_date !== undefined &&
//   record.nextConsultation_date
// )
// );
} ,
isConsultationDataValidNoCheckbox(){
const mobilevalidation = /^[6-9]\d{9}$/;
return mobilevalidation.test(this.publicPayload.mobile)
},
    
        uniqueOptions() {
            const combinedOptions = [...this.clinicConsult, ...this.homeConsult];

            // Filter out duplicates if needed
            const uniqueOptions = Array.from(new Set(combinedOptions.map(option => option.id)))
                .map(id => combinedOptions.find(option => option.id === id));

            return uniqueOptions;
        }
},
watch: {
        uniqueOptions: {
            immediate: true,
            handler(newOptions) {
                if (newOptions.length === 1) {
                    this.selected = newOptions[0].id;
                } else if (newOptions.length > 1) {
                    this.selected = '1'; // Reset to "Select Clinic" when there are multiple options
                }
            }
        }
    },
methods: {

  cancelCustom() {
      this.patientSupport = false
      this.isHide = true

    },

  showModal(key) {
      this.selectedMeasurement.key = key;
      this.selectedMeasurement.text = this.footMeasurement[key].text;
      this.selectedMeasurement.image = this.footMeasurement[key].image;
    },
    nextImage() {
      if (this.currentIndex < this.imagesPreview.length - 1) {
        this.currentIndex++;
      }
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    showImagePreview(images) {
      this.imagesPreview = images;
      this.showDropdown = false;
    },

  async fetchDoctorClinicAndInventory() {
     try {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;

    const doctorResponse = await axios.get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`);
    this.doctorData = doctorResponse.data;
    this.doctorName=this.doctorData.firstName
    console.log(this.doctorName ,"this.doctorData ")
    this.clinicId = this.doctorData.clinic_hcp_id;

    const clinicResponse = await axios.get(`${BASE_API_URL}/clinic-consultation/${this.clinicId}/one`);
    this.clinicData = clinicResponse.data;
    this.adminCutomerId = this.clinicData.admin_customer_id.id;

    if (!this.adminCutomerId) {
      console.error("adminCutomerId is not set ");
      return;
    }
    const inventoryResponse = await axios.get(`${BASE_API_URL}/foot-secure/inventory`);
    this.inventoryData = inventoryResponse.data?.filter(item => item.admin_customer_id.id === this.adminCutomerId);

    this.inventoryData.forEach(item => {
      if (item.images && item.images.length > 0) {
        item.imagesPreview = item.images.map(imageName => {
          return `${BASE_API_URL}/path/to/images/${imageName}`;
        });
      } else {
        item.imagesPreview = [];
      }
    });
  } catch (error) {
    console.error("Error in fetchDoctorClinicAndInventory:", error);
  }
},


  handleLeftImageUpload(imageData) {
      this.uploadedLeftImage = imageData;
    },

    handleRightImageUpload(imageData) {
      this.uploadedRightImage = imageData;
    },

    createFootwearData() {
      this.createFootwear = true
      this.hideCreateFootButton=false

    },
mobileNumberValidate(){
  this.publicPayload.mobile = this.publicPayload.mobile.replace(/\D/g, '');
},
validateInputUser(index) {
  const inputValue = this.otpNumbersUser[index];
  const isValid = /^\d$/.test(inputValue); 
  if (!isValid) {
    this.otpNumbersUser[index] = ''; 
  }
  this.$nextTick(() => {
    if (this.otpNumbersUser[index] !== '' && index < this.otpNumbersUser.length - 1) {
        const otpInput = this.$refs.otpInputs;
        if (otpInput && otpInput.length > 0) {
    otpInput[index+1].focus();
  }
}
});
},
    clearAll(){
       this.clearPatient();
    },
    isShowAddConsult() {
        this.upload_Record.push({
    caseSheet: "",
    nextConsultation_date: null,
    uploadImage: null,
  });
},
    toggle() {
        this.ToggleButton = !this.ToggleButton
    },
    printDate: function () {
        return new Date().toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).split(' ').join('-');
    },
    async listPatient() {
        this.openSave=false
        this.patientSupport=false
        this.newPublic=null
        localStorage.setItem('mobile', this.publicPayload.mobile)
     const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
  let payload = {
    mobile: this.publicPayload.mobile,
  };
await  axios
    .post(`${BASE_API_URL}/public-user/get-sign`, payload)
        .then( (response) => {
        this.userPayload = response.data
        if (response?.data ) {
        this.issubmit = true;
        }else  {
        this.isNot = true;
        this.issubmit = false;
        }
        })

        localStorage.setItem('mobile', this.publicPayload.mobile)
        await axios
            .get(`${BASE_API_URL}/add-patient`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                this.addPatientInfo = response.data;
            })
        await this.addPatientInfo.map((data) => {
            if(data.mobile === this.publicPayload.mobile){

                if (data.mobile === this.publicPayload.mobile) {
                    this.mobileData.push(data)
                }
                else{
                    this.isNot=true
                }
            }
        })
        await  axios
    .post(`${BASE_API_URL}/hcps/get-sign`, payload)
        .then( (response) => {
            this.doctorResponse=response.data
            let mobileNumber = localStorage.getItem('mobile')
            if(this.doctorResponse?.mobile===mobileNumber){
                this.isNot = false;
                this.issubmit = false
               this.$swal("The mobile number entered is of a doctor. Currently, RxIx does not allow the same mobile number for both doctor and a patient. If the doctor is also patient, use a diffrent mobile number.");
            }
        })
        if(this.openAddPatient){
            this.isNot=false
        }
       
    },
    async clearPatient() {
        this.publicPayload.firstName="",
        this.publicPayload.lastName="",
        this.publicPayload.age="",
        this.publicPayload.gender="1",
        this.publicPayload.pincode="",
        this.publicPayload.mobile=""
        this.issubmit = false;
        this.isNot = false;
        this.otpNumbersUser= ['', '', '', ''],
        this.timegreater=true
        clearInterval(this.timerUser);
        this.enableResendBtnUser=false
        this.createPatient=false
        this.otpIsVerified=false
        this.enterOTP=false;
        this.openOTP = false
        this.openAddPatient=false;
        this.upload_Record= [{
        caseSheet: "",
        nextConsultation_date: "",
        uploadImage: "",}]
        this.selected ='1'
    },
    patientData() {
        this.selectFamilyRecord = false
        this.selectUserRecord = false
        this.openAddPatient = true
        this.previousConsultation = true
        if(this.openAddPatient){
            this.isNot=false
        }
    },
    changeFirstName(){
  let name =this.publicPayload.firstName.toLowerCase()
  return name.charAt(0).toUpperCase() + name.slice(1);
},
changeLastName(){
  let name =this.publicPayload.lastName.toLowerCase()
  return name.charAt(0).toUpperCase() + name.slice(1);
},
    addPatientData() {
        this.isAgree='';
        this.notAgree=''
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        this.openSave = this.openSave ? false : true;
        this.openSave = this.openSave ? false : true;
        this.openSave = true
        this.patientSupport = true
        let payloadss = {
            firstName: this.changeFirstName(),
            lastName: this.changeLastName(),
            age: this.publicPayload.age,
            gender: this.publicPayload.gender,
            mobile: this.publicPayload.mobile,
            pincode:this.publicPayload.pincode,
            role: "user"
        }
        axios
            .post(`${BASE_API_URL}/add-patient`, payloadss,)
            .then((response) => {
                this.firstName = response.data.firstName;
                this.lastName = response.data.lastName
                this.age = response.data.age;
                this.gender = response.data.gender;
                this.pincode = response.data.pincode;
                 localStorage.setItem('mobile', this.publicPayload.mobile)
                this.getMobile = localStorage.getItem('mobile');
                 this.getPatientDesk()

            })
    },
    fileSelect(event,index) {
        this.upload_Record[index].uploadImage = event.target.files?.[0]
    },
    async compressImage(file) {
        if(file){
return new Promise((resolve) => {
const quality = 0.8; // Adjust quality as needed
const maxWidth = 1000; // Adjust max width as needed
const maxHeight = 1000; // Adjust max height as needed
const image = new Image();

image.onload = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = maxWidth;
  canvas.height = maxHeight;
  ctx.drawImage(image, 0, 0, maxWidth, maxHeight);

  canvas.toBlob(
    (blob) => {
      const compressedFile = new File([blob], file.name, {
        type: 'image/jpeg', // Change the type as needed
        lastModified: Date.now(),
      });
      resolve(compressedFile);
    },
    'image/jpeg', // Change the type as needed
    quality
  );
};

image.src = URL.createObjectURL(file);
});
}
},

getPatientDesk() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
    .get(`${BASE_API_URL}/add-patient`, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then(async (response) => {
       const patientData= response.data.filter(data=>data.mobile === this.getMobile)
       this.userRecordsData=patientData[0]
        const patientGender=patientData[0].gender
        this.gender=patientGender                                                           
    })
    },

async getDoctor() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
    try {
        const response = await axios.get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`);
        this.doctorPayload = response.data;
        return this.doctorPayload; 
    } catch (error) {
        console.error("Error fetching doctor data", error);
        return null; 
    }
},



updateImagesPreview(item) {
      this.currentIndex = 0;
      this.selecrtedColourId = null
      this.selectedModelId = item.id;
      this.colourName = null
      this.getModelId = item;
      this.imagesPreview = item ? item.images : [];

      this.filteredColors = this.colourData.filter(color => item.colour_id.includes(color.id.toString()));

      this.selectedColorImage = item ? item.images[0] : '';
      this.userSelected = '';
    }
    ,

    updateImagesColour(id) {
      console.log(id, "id")
      this.selecrtedColourId = id.id;
      this.getColourId = id
      this.colourName = id.colour_name
    },
    selectFile(inputId) {
      document.getElementById(inputId).click();
    },
    handleFileChange(event, fileType) {
      const file = event.target.files[0];
      if (file) {
        this.files[fileType] = file;
        this.uploadedFiles[fileType] = true;
      }
    },
    updateAreaToOffload(area, side, event) {
      this.areasToOffload[area][side] = event.target.checked ? 'Yes' : 'No';
    },
    updateOffloadingMethods(method, side, event) {
      this.Offloading_methods[method][side] = event.target.checked ? 'Yes' : 'No';
    },

async saveConsultation() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    const generalInformation = {
      "id": 24,
      "disorder_id": "24",
      "disorder": "General Information"
      };
      this.getDisorderArray.push(generalInformation)
let payload = {
    firstName: this.changeFirstName(),
    lastName: this.publicPayload.lastName.toUpperCase(),
    age: this.publicPayload.age,
    gender:this.publicPayload.gender,
    mobile:this.publicPayload.mobile,
    getDisordersId: this.getDisorderArray,
    role: "user"
}

console.log(payload,"payload")
axios
    .post(`${BASE_API_URL}/public-user`, payload, {
        headers: {
            "Content-Type": "application/json",
        },
    })
.then(async (response) => {
    if (response?.data) {
        this.newPublic=response.data

         // const requiredFields = [
      // { field: this.height, name: "Height" },
      // { field: this.weight, name: "Weight" },
      //   { field: this.footArch.left, name: "Left foot arch" },
      //   { field: this.footArch.right, name: "Right foot arch" },
      //   { field: this.jointMobility.left, name: "Left joint mobility" },
      //   { field: this.jointMobility.right, name: "Right joint mobility" },
      //   // { field: this.footMeasurement.left, name: "Fill all Foot Measurement " },
      //   { field: this.getModelId , name: "Model" },
      //   { field: this.getColourId, name: "Colour" },
      //   { field: this.selectedSize, name: "Size" },

      //   { field: this.files.ppsVideoFile, name: "PPS video" },
      //   { field: this.files.anteriorVideoFile, name: "Anterior Video" },
      //   { field: this.files.posteriorVideoFile, name: "Posterior video" },
      //   { field: this.files.bilateralVideoFile, name: "Bilateral video" },
      //   { field: this.files.leftLateralPhoto, name: "Left lateral photo" },
      //   { field: this.files.leftMedialPhoto, name: "Left medial photo" },
      //   { field: this.files.rightLateralPhoto, name: "Right lateral photo" },
      //   { field: this.files.rightMedialPhoto, name: "Right medial photo" },
      //   { field: this.files.plantarPhoto, name: "Plantar photo" },
      //   { field: this.files.dorsalPhoto, name: "Dorsal photo" },
      //   { field: this.files.anteriorPhoto, name: "Anterior photo" },
      //   { field: this.files.posteriorPhoto, name: "Posterior photo" },
      //   { field: this.files.leftFootSketch, name: "Left foot sketch" },
      //   { field: this.files.rightFootSketch, name: "Right foot sketch" },



      // ];

      // for (let i = 0; i < requiredFields.length; i++) {
      //   const { field, name } = requiredFields[i];
      //   if (!field) {
      //     this.$swal({
      //       icon: "warning",
      //       title: "Missing Information",
      //       text: `${name} is required. `,
      //     });
      //     return; 
      //   }
      // }
        for(let i=0;i<this.upload_Record.length;i++){
        const compressedImage = await this.compressImage(this.upload_Record[i].uploadImage);
        if(compressedImage){
if (compressedImage.size < 22240) {
this.$swal("Image not clear. Please Upload image of better quality.");
return;}}
this.date=moment(this.date).format("YYYY-MM-DD")
let formData = new FormData();
formData.append('doctor_name', this.doctorName);
formData.append('doctor_id', this.DoctorId);
formData.append(
      'caseSheet',
      `${this.upload_Record[i].chiefComplaint}, ${this.upload_Record[i].ClinicalSignsSymptoms}, ${this.upload_Record[i].DiagnosisDifferentialDiagnosis}`)
formData.append('file', compressedImage);
formData.append('isConsultation', 1);
formData.append('nextConsultationDate',this.upload_Record[i].nextConsultation_date)
formData.append("consultation_date", this.date)
formData.append('user_id', this.newPublic.id);
formData.append('family_id', this.familyID);
if (this.selected) {
    if (this.selected.type === 'clinic') {
        formData.append('clinic_id', this.selected.id);
    } else if (this.selected.type === 'home') {
        formData.append('home_id', this.selected.id);
    }
}

    axios
    .post(`${BASE_API_URL}/upload-records`, formData)
    .then((response) => {
        if (response?.data) {
          this.getUploadData = response?.data?.data?.id;

     this.publicDoctorCommunityData=[];
    this.publicDoctorCommunityData.push(this.doctorPayload)
    const signInPayload ={
        doctorsID: this.publicDoctorCommunityData,
    }
       axios
    .put(`${BASE_API_URL}/public-user/${this.newPublic.id}/update`, signInPayload, {
        headers: {
            "Content-Type": "application/json",
        },
    })

    const footwearPayload = new FormData();
            const footArchPayload = {
              left: this.footArch.left,
              right: this.footArch.right,
            };

            const jointMobilityPayload = {
              left: this.jointMobility.left,
              right: this.jointMobility.right,
            };


            footwearPayload.append('foot_arch', JSON.stringify(footArchPayload));
            footwearPayload.append('Joint_mobility', JSON.stringify(jointMobilityPayload));
            footwearPayload.append('Areas_to_offload', JSON.stringify(this.areasToOffload));
            footwearPayload.append('Offloading_methods', JSON.stringify(this.Offloading_methods));
            footwearPayload.append('Offloading_Recommendations', this.Offloading_Recommendations);
            footwearPayload.append('footMeasurement', JSON.stringify(this.footMeasurement));
            footwearPayload.append('upload_records_data', this.getUploadData);
            footwearPayload.append('patient_name', this.newPublic.id);
            footwearPayload.append('selected_model', this.getModelId.model_no);
            footwearPayload.append('selected_colour_model', this.getColourId.id);
            footwearPayload.append('confirmed_model', this.getModelId.model_no);
            footwearPayload.append('order_status', "Created");
            footwearPayload.append('height', this.height);
            footwearPayload.append('weight', this.weight);
            footwearPayload.append('size', this.selectedSize);
            footwearPayload.append('admin_customer_id', this.adminCutomerId);
            footwearPayload.append('doctorId', this.DoctorId);

            footwearPayload.append('upload_files[ppsPdfFile]', this.files.ppsPdfFile);
            footwearPayload.append('upload_files[ppsVideoFile]', this.files.ppsVideoFile);
            footwearPayload.append('gait_videos[anteriorVideoFile]', this.files.anteriorVideoFile);
            footwearPayload.append('gait_videos[posteriorVideoFile]', this.files.posteriorVideoFile);
            footwearPayload.append('gait_videos[bilateralVideoFile]', this.files.bilateralVideoFile);
            footwearPayload.append('foot_photographs[leftLateralPhoto]', this.files.leftLateralPhoto);
            footwearPayload.append('foot_photographs[leftMedialPhoto]', this.files.leftMedialPhoto);
            footwearPayload.append('foot_photographs[rightLateralPhoto]', this.files.rightLateralPhoto);
            footwearPayload.append('foot_photographs[rightMedialPhoto]', this.files.rightMedialPhoto);
            footwearPayload.append('foot_photographs[plantarPhoto]', this.files.plantarPhoto);
            footwearPayload.append('foot_photographs[dorsalPhoto]', this.files.dorsalPhoto);
            footwearPayload.append('foot_photographs[anteriorPhoto]', this.files.anteriorPhoto);
            footwearPayload.append('foot_photographs[posteriorPhoto]', this.files.posteriorPhoto);
            footwearPayload.append('foot_sketch_images[leftFootSketch]', this.files.leftFootSketch);
            footwearPayload.append('foot_sketch_images[rightFootSketch]', this.files.rightFootSketch);
            footwearPayload.append('uploadedLeftImage', JSON.stringify((this.uploadedLeftImage)));
            footwearPayload.append('uploadedRightImage', JSON.stringify((this.uploadedRightImage)));

            const footwearOrderResponse = axios.post(`${BASE_API_URL}/footwear-order`, footwearPayload, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            if (footwearOrderResponse?.data) {
          this.$swal("Created!", "The order has been created!", "success");
          this.fetchDoctorClinicAndInventory();
          this.getDataConsultation(); 
          this.getDoctor()
          this.getInventoryColor() 
          this.getPatientDesk()
              console.log("Success", footwearOrderResponse.data);
            
            }
            this.clearPatient();
    }
    }) }
    }}) 
},
openConsultation(index, id) {
const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
this.openDropData = false
let Payload = {
    doctor_id: this.doctor_id,
    is_otp: true,
    id
}
this.previous = index
this.uploadDataRecord = localStorage.getItem(id);
axios
    .put(`${BASE_API_URL}/family-member/${this.uploadDataRecord}/update`, Payload, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
        this.uploadPayloadRecord = response.data;
    })
    .catch(function (error) {
        console.log(error.response);
    });
},
    startTimerUser() {
  if (this.timeLeftUser > 0) {
    this.timegreater=false
this.timerUser = setInterval(() => {
  this.timeLeftUser--;
  if (this.timeLeftUser <= 0) {
    this.timegreater=true
    clearInterval(this.timerUser);
    this.enableResendBtnUser = true;
    this.enterOTP=false
  }
}, 1000);
}
},
    GenerateOTP() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
let payload = {
    mobile: this.publicPayload.mobile,
  };
 axios
    .post(`${BASE_API_URL}/generateotpuser`, payload)
    .then((response)=>{
        if(response.data.type === 'success'){
    this.timeLeftUser= 30;
    clearInterval(this.timerUser);
        this.startTimerUser();
        this.enterOTP=true;
        this.openOTP = true
this.$nextTick(() => {
        const otpInput = this.$refs.otpInputs;
  if (otpInput && otpInput.length > 0) {
    otpInput[0].focus();
  }
});
        }
    })
    },
    verityOTPUser(){

        this.createPatient=true
        this.otpIsVerified = true

//   const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
//         axios
//     .get(`${BASE_API_URL}/verifyotpuser/${this.combinedOTPUser}/${this.publicPayload.mobile}`)
//     .then((response) => {
//       if(response.data.type === 'success'){
//         
//         this.enableResendBtnUser=false
//         this.otpIsVerified = true
//       } else{
//         this.$swal("Please enter valid otp");
//       }
//     })
    },
    resendOTPUser(){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
 axios
 .get(`${BASE_API_URL}/resendotpuser/${this.patientData.mobile}`)
   .then((response)=>{
     if(response.data.type === 'success'){
    this.otpNumbersUser=['', '', '', '']
    this.timeLeftUser=30;
    clearInterval(this.timerUser);
      this.startTimerUser();
      this.enterOTP=true;
      this.$nextTick(() => {
        const otpInput = this.$refs.otpInputs;
  if (otpInput && otpInput.length > 0) {
    otpInput[0].focus();
  }
    });
     }
   })
},

getInventoryColor() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  axios
    .get(`${BASE_API_URL}/foot-secure/inventory/colour`)
    .then((response) => {
      this.colourData = response.data?.filter(item => item)
      this.colourData.forEach(item => {
        if (item.images && item.images.length > 0) {
          item.imagesPreview = item.images.map(imageName => {
            return `${BASE_API_URL}/path/to/images/${imageName}`; 
          });
        } else {
          item.imagesPreview = [];
        }
      });
    })
    .catch((error) => {
      console.error("Error fetching inventory:", error);
    });
},
getDataConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
    .get(`${BASE_API_URL}/clinic-consultation`)
    .then((response) => {
      this.clinicConsult = response.data
      .filter(item => item.hcps_id?.id === this.DoctorId || item.id == this.doctorPayload?.clinic_hcp_id)
      
        .map(item => ({ ...item, type: 'clinic' })); // Add type property

        const missingMobile = this.clinicConsult.some(item => !item.mobile);

        if (missingMobile) {
            Swal.fire({
              title: "Missing Information",
              text: "Please update missing information of your places of consultation",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "OK",
              cancelButtonText: "Cancel"
            }).then((result) => {
              const query = this.$route.query;
              if (result.isConfirmed) {

            window.scrollTo(0, 0);
            this.$router.push({ path: `consultation-place`, query });
              } else {
                
            window.scrollTo(0, 0);
            this.$router.push({ path: `/doctor/index`, query });
              }
            });
          }

        console.log(this.clinicConsult.mobile,"this.clinicConsult")
    });
  
  axios
    .get(`${BASE_API_URL}/home-consultation`)
    .then((response) => {
      this.homeConsult = response.data
        .filter(item => item.hcps_id?.id === this.DoctorId)
        .map(item => ({ ...item, type: 'home' })); // Add type property
    });
        }
}
};
</script>
<style>
.consultation-select{
width: 400px;
font-size: 20px;
margin-left: auto;
margin-right: auto;
text-align: left;
margin-bottom: 5px;
-webkit-appearance: auto;
appearance:auto;
}
.disableCreateBtn{
color:  #dddddd !important;
pointer-events: none;
cursor: auto; 
}
.Otpinputs{
width: 300px;
margin: auto;
}

.foot-input {
  width: 60px !important;
  border: none
}
.disableBtn{
background: #cccccc !important;
}
#countdowntimer{
color: red;
}
fieldset.curve-box {
  border: 2px solid #00979e;
  border-radius: 20px;
  padding: 15px;
}
.star {
color: red;
font-size: 20px;
}
div.curveBoxes {
margin: 10px 0px 0px 10px;
background-color: #ffff;
border-radius: 10px;
padding: 15px;
box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%);

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
.selected-color-image {
  margin-top: 10px;
  max-width: 100%; 
  height: 100px; 
  width: 100px; 

}
.consultation-select-inventory{
width: 180px !important;
font-size: 16px !important;
margin-left: auto;
margin-right: auto;
text-align: left;
margin-bottom: 5px;
-webkit-appearance: auto;
appearance:auto;
}
.searchBtn{
display: flex;
justify-content: flex-start;
}
.question-icon{
cursor: pointer;
}
.imagesTexts {
text-align: center;
padding-top: 12px;
padding-bottom: 12px;
}

.border-green {
  border: 2px solid green;
  border-radius: 5px;
  padding: 3px
}

.imageUploadDetails {
position: relative;
width: 427px;
border-radius: 0.25em;
text-align: left;
/* right: 55px; */
/* bottom: 113px; */
z-index: 99;
margin-left: auto;
margin-right: auto;
border: 1px solid #000000;
}

.imageUploadDetails:before,
.imageUploadDetails:after {
position: absolute;
z-index: -1;
content: '';
}

.imageUploadDetails:after {
top: 0;
right: 0;
bottom: 0;
left: 0;
border-radius: inherit;
background: yellow;
}

.imageUploadDetails:before {
border: solid 2px #242424d4;
border-right: solid 3.5em yellow;
bottom: 0.25em;
left: 0.25em;
width: 0;
height: 1em;
transform: rotate(140deg) skewX(75deg);
}
.noRecordfoundMainDiv {
display: flex;
align-items: center;
justify-content: center;
}

.searchPatientText {
margin-top: 0rem;
display: flex;
justify-content: flex-start;
}

.noRecordText {
color: red;
}

.addUserImg {
border: 3px solid #00979e;
height: 100%;
padding: 20px 50px;
margin: 5px;
width: max-content;
}

.addPluesIcon {
width: 100px;
display: block;
box-sizing: border-box;
margin-left: auto;
margin-right: auto;
margin-top: 10px;
cursor: pointer;
}

.text-center.name-fix {
text-align: center !important;
font-size: large;
font-weight: bold;
}

.add-consultation {
margin-left: 1rem;
color: #00979e;
width: 8rem;
}
textarea.doctorWritenPlace {
width: 100%;
height: 60px;
padding: 12px 20px;
box-sizing: border-box;
border: 2px solid #ccc;
border-radius: 4px;
background-color: #ffffff;
font-size: 20px;
}

form.caseSheetTextAreas {
width: 100%;
margin-bottom: auto;
margin-left: auto;
margin-right: auto;
}

.prescription-image {
width: 50px;
height: 50px;
}

.prescriptionParaFont {
font-size: 10px;
margin-bottom: 0px;
width: 87px;
}
.record:disabled{
  width: 10% !important
}
.arrowRightImage {
width: 34px;
height: 34px;
margin-top: 8px;
}

@media screen and (max-width:991px) {
div.curveBoxes {
    margin: 0px;
    padding:0px;
    background-color: #ffff;
    border-radius: none;
    box-shadow: none;
}
.EnterOTPdiv {
    margin-top: 1rem;
    text-align:center ;
}
.enterOTPLabelText {
    margin-left: 25px;
}
}

@media screen and (max-width:769px) {
form.caseSheetTextAreas {
    width: 100%;
}
}

@media screen and (max-width:615px) {
.text-center.name-fix {
    font-size: 15px;
}
}
@media screen and (max-width:576px) {
.addUserImg {
    padding: 12px;
}

.record:disabled{
  width: 30% !important
}
.addPluesIcon {
width: 60px;
}
}
@media screen and (max-width:530px) {
.prescription-image {
    width: 36px;
    height: 36px;
}

.imageUploadDetails {
    width: 315px;
}

.arrowRightImage {
    width: 20px;
    height: 20px;
    margin-top: 8px;
}

.imagesTexts {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 1px;
    margin-left: 7px;
}
}

@media screen and (max-width:400px) {
.prescriptionParaFont {
    font-size: 9px;
    margin-bottom: 0px;
    width: 55px;
}

.imageUploadDetails {
    width: 260px;
    padding-left: 5px;
    padding-right: 5px;
}

.imagesTexts {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 7px;
    margin-left: 9px;
}
}

@media screen and (max-width:391px) {
.text-center.name-fix {
    font-size: 13px;
}

.noRecordText {
    font-size: 19px;
}

.OtpButton {
    font-size: 11px;
}


}
</style>