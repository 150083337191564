<!-- eslint-disable vue/no-textarea-mustache -->
<template>
    <div class="m-2">
        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->

        <!-- patient records for doctor consultationdesk view -->
        
        <div class="row">
    <div class="col-12">
      <div class="row">
        <label
          style="text-align: end; color: #00979e; cursor: pointer;"
          @click="showNewer"
          v-if="hasPrevPage"
        >
          Newer
        </label>
        <div v-if="visibleRecords.length"
          class="col-lg-3 col-md-3 col-sm-3 col-6"
          v-for="(record) in visibleRecords"
          :key="record.id"
        >
          <div v-if="upFamily?.id === record.family_id?.id">
            <a
              class="nav-link bg-color text-white text-center cursor-pointer my-1 dateButton"
              id="date1-tab"
              data-toggle="pill new"
              @click="Consultation(record)"
              role="tab"
              aria-controls="date-1"
              aria-selected="false"
            >
              {{ formatDate(record.consultation_date) }}
            </a>
          </div>
        </div>
        <div v-else class="text-align text-center">
     <h4 style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63)">Records not available</h4>
     </div>
        <label
        style="text-align: end; color: #00979e; cursor: pointer;"
          @click="showOlder"
          v-if="hasNextPage"
        >
          Older
        </label>
      </div>
    </div>
  </div>
        
        <!-- <div>
            <div class="row" v-if="previousConsultation">
                <div class="mt-3">
                    <hr class="hr-border" />
                </div>
                <div class="col text-center">
                    Please get OTP from patient to view past records.
                </div>
            </div>

            <div class="row" v-if="previousConsultation">
                <div class="col-4 mt-4">
        <button class="btn btn-color text-white" style="float: right;" @click="GenerateOTP"  v-if="!enableResendBtnUser && openDropData" :class=" !buttonClickedUser ? 'disableBtn': ''">Send OTP</button>
        <button class="btn btn-color text-white " style="float: right;" @click="ResendOTP" v-if="enableResendBtnUser && openDropData" :class=" !buttonClickedUser ? 'disableBtn': ''">Resend OTP</button>
        
                </div>
                <div class="col-4">
                    <div class="inputs d-flex flex-row justify-content-center" ref="otpBoxUser">
                <input v-for="(number,index) in otpNumbersUser" :key="index" class="m-2 text-center form-control rounded" type="text" maxlength="1"
        v-model="otpNumbersUser[index]" @input="validateInputUser(index)" :disabled="!getOTP" v-on:keyup.enter="combinedOTPUser ? verifyOTP() : null" />
     </div>
                </div>
                <div class="col-4 mt-4">
                    <button class="btn btn-blue-color text-white" :class="!combinedOTPUser ? 'disableBtn': ''" :disabled="!combinedOTPUser" v-if="openDropData" @click="verifyOtp()">Verify
                        OTP</button>
                </div>
                <div class="row mt-4">
                    <div class="text-align text-center"  v-if="timeLeftUser > 0 && getOTP ">
                            <p  >Resend OTP in <span id="countdowntimer">{{ timeLeftUser }}</span> Seconds</p>
                        </div> 
                    
                </div>
            </div>
        </div> -->

        <div  v-for="index in uploadRecord" :key="index">
            <!-- <div  v-if="previousConsultation" v-for="index in uploadRecord" :key="index"> -->
            <div v-if="previousConsultation === index">
                <div class="row mt-3">
                    <div class="col-md-12 col-12">
                        <h3 class="text-center"> {{ formatDate(index.consultation_date) }}</h3>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-12">
                        <b class="mb-0"> {{ index.doctor_name }}</b>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12">
                        <p class="mb-0">Case sheet</p>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12 col-12">
                        <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner" >
                                <form class="caseSheetTextAreas">
                                    <textarea class="doctorWritenPlace" style="cursor: not-allowed;" readonly>{{ index.caseSheet }}</textarea>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-sm-12">
                        <p class="">Prescription</p>
                    </div>
                </div>
                <!-- PRESCRIPTION FOR DESKTOP AND TABLET  -->
                <div  v-if="index.upload_prescription">
                    <div class="row mb-3 container">
                        <div class="col-md-12 col-12">
                        <img class="mx-auto d-block" :src="`${index.upload_prescription}`"
                            alt="Prescription" :style="{ transform: 'rotate(' + previousConsultation.rotationAngle + 'deg)' }">
                        </div>
                    </div>
                    <button style="float: right;"  class="btn btn-color text-white mb-3 mx-3" @click="rotateImage(previousConsultation)">Rotate</button>
                </div>
                <div   v-else>
                    <h4 style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63)">No prescription </h4>
                </div>
               
            </div>
        </div>

        <!-- <div class="row" v-if="previousConsultation">
            <div class="col-1"></div>
            <div class="col-9 mt-3" style="width: 70%; margin-left: auto;">
                <hr class="hr-border" />
            </div>
            <div class="col-2">
                <button type="button" @click="patientSupportData()" style="font-size: 30px;margin-left: 8rem;"
                    id="btn_schedule_add" class="btn btn_add">
                    <font-awesome-icon :icon="['fas', 'plus-circle']" />
                </button>
                <label class="add-consultation-plus">Add consultation</label>
            </div>
                </div> -->
        <div v-if="patientSupport == true">
            <h5 class="taskmanager-title1" v-show="date">{{ date }}</h5>
            <div class="row mt-2">
                <div class="col-sm-12">
                    <p class="">Case sheet</p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-md-12 col-12">
                    <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <form class="caseSheetTextAreas">
                                <textarea class="doctorWritenPlace" v-model="caseSheet"></textarea>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-8" v-for="records in hcp_Types" :key="records.index">
                    <input type="file" class="form-control mt-4" @change="fileSelect($event)">
                </div>
                <!-- <button class="btn btn-color text-white border-curve"  type="file" id="files"
                            @click="onFileChanged($event)" style="margin-top:25px;">Upload
                                        prescription</button>  -->
                <div class="col-2">
                    <button type="button" @click="hcpTypesForm()" id="btn_schedule_add" class="btn btn_add plus">
                        <font-awesome-icon :icon="['fas', 'plus-circle']" style="margin-top:20px;font-size: 30px;" />
                    </button>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-8">
                    <label class="" for="date">Next consultation date</label>
                    <input type="date" id="datepicker" class="form-control" v-model="consultation_date" />
                </div>
                <div class="col-4 mt-3">
                    <label class="" for="date">Upload prescription</label>
                    <!-- <img class="img-check icon" src="@/assets/images/check.png" /> -->
                    <button class="btn btn-blue-color text-white" @click="uploadData()">Close consultation</button>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>
<script>
import axios from 'axios';
// import moment from 'moment';
export default {
    props: ["accordionCollapse", "accordionId", 'upValue', 'upFamily','upMobileRecord'],
    data() {
        return {
    date: '',
    addSchedule1: false,
    addSchedule2: false,
    addSchedule3: false,
    previousConsultation: null,
    getRecords: [],
    uploadRecord: [],
    previous: null,
    patientSupport: false,
    hcp_Types: [
        {
            one: "",
        },
    ],
    uploadPayloadRecord: {},
    otpData: [],
    showWithoutotp: false,
    currentPage: 1,
    recordsPerPage: 8,
    otpNumbersUser: ['', '', '', ''],
    timeLeftUser: 30,
    timerUser: null,
    buttonClickedUser: true,
    enableResendBtnUser:false,
    openDropData:true,
    getOTP:false
        }
    },
    created: function () {
        this.uploadData();
        this.date = this.printDate();
        this.withoutOtp()
    },

    computed: {
        combinedOTPUser() {
            if (this.otpNumbersUser.some(input => !/^\d$/.test(input))) {
        return '';
      }
      return this.otpNumbersUser.join('') 
    },
        paginatedData() {
    const startIndex = (this.currentPage - 1) * this.recordsPerPage;
    const endIndex = startIndex + this.recordsPerPage;
    return this.uploadRecord.slice(startIndex, endIndex);
  },
  hasPrevPage() {
    return this.currentPage > 1;
  },
  hasNextPage() {
    return this.currentPage < this.totalPages;
  },
  totalPages() {
    return Math.ceil(this.uploadRecord.length / this.recordsPerPage);
  },
  visibleRecords() {
    return this.paginatedData;
  }
},
mounted() {
  this.setRecordsPerPage(); 
},
    methods: {
        rotateImage(consultation) {
            consultation.rotationAngle += 90;
    },
        startTimerUser() {
      if (this.timeLeftUser > 0) {
    this.timerUser = setInterval(() => {
      this.timeLeftUser--;
      if (this.timeLeftUser <= 0) {
        clearInterval(this.timerUser);
        this.getOTP=false
        this.enableResendBtnUser = true;
        this.sendOTPUser = false;
        this.buttonClickedUser = true;
      }
    }, 1000);
  }
    },
        validateInputUser(index) {
      const inputValue = this.otpNumbersUser[index];
      const isValid = /^\d$/.test(inputValue); 
      if (!isValid) {
        this.otpNumbersUser[index] = ''; 
      }
      if (this.otpNumbersUser[index] !== '' && index < this.otpNumbersUser.length - 1) {
        this.$refs.otpBoxUser.querySelectorAll('input')[index + 1].focus();
    }
    },
        GenerateOTP(){
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            let payload = {
        mobile: this.upFamily.users.mobile,
      };
     axios
     
        .post(`${BASE_API_URL}/generateotp/user`, payload)
        .then((response)=>{
            if(response.data.type === 'success'){
            this.startTimerUser();
            this.buttonClickedUser=false
            this.getOTP=true
            setTimeout(() =>{
                    this.$refs.otpBoxUser.querySelectorAll('input')[0].focus()
                }, 100)
            }
    })
        },
        verifyOtp(index, id) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
                  axios
        .get(`${BASE_API_URL}/verifyotp/user/${this.combinedOTPUser}/${this.upFamily.users.mobile}`)
        .then((response) => {
          if(response.data.type === 'success'){
            this.enableResendBtnUser=false
            this.openDropData = false
            let Payload = {
                doctor_id: this.doctor_id,
                is_otp: true,
                id
            }
            this.previous = index
            this.uploadDataRecord = localStorage.getItem(id);
            axios
                .put(`${BASE_API_URL}/family-member/${this.uploadDataRecord}/update`, Payload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    this.uploadPayloadRecord = response.data;
                })
                .catch(function (error) {
                    console.log(error.response);
                });
                    }else{
            this.$swal("Please enter valid otp");
          }
        })
        },
        ResendOTP(){
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
     axios
     .get(`${BASE_API_URL}/resendotp/user/${this.upFamily.users.mobile}`)
       .then((response)=>{
         if(response.data.type === 'success'){
            this.otpNumbersUser = ['', '', '', ''];
        this.getOTP=true
      this.buttonClickedUser=false
        this.timeLeftUser=30;
          this.startTimerUser();
            this.sendOTPUser=true
            setTimeout(() =>{
                    this.$refs.otpBoxUser.querySelectorAll('input')[0].focus()
                }, 100)
         }
       })
        },
        setRecordsPerPage() {
    if (window.innerWidth <= 575) {
      this.recordsPerPage = 4; // Set 4 records per page for smaller screens
    } else {
      this.recordsPerPage = 8; // Set 8 records per page for larger screens
    }
  },
        showOlder() {
      if (this.hasNextPage) {
        this.currentPage++;
      }
    },
    showNewer() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
        formatDate(dateString) {
            const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
        updateVisibleRecords() {
            // Call the visibleRecords computed property to update the displayed records
            this.visibleRecords;
        },
        printDate: function () {
            return new Date().toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            }).split(' ').join('-');
        },
        // patientSupportData() {
        //     this.patientSupport = true
        //     return new Date().toLocaleDateString();
        // },
        hcpTypesForm() {
            this.hcp_Types.push({
                one: "",
            });
        },
        Consultation(index) {
            this.previousConsultation = index;
            // this.showWithoutotp = index;
        },

        uploadData() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/upload-records`)
                .then((response) => {
                    if (response?.data) {
                        response.data.map((filterData)=>{
                            if(filterData?.family_id?.id === this.upFamily?.id){
                            filterData.rotationAngle=0
                                this.uploadRecord.push(filterData)
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        fileSelect(event) {
            this.uploadImage = event.target.files?.[0]
        },
        withoutOtp() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/manage-access/`,)
                .then((response) => {
                    if (response.data) {
                        this.otpData = response.data;
                    }
                })
        },
    }
}
</script>
<style>
.disableBtn{
  background: #cccccc !important;
}
.record-div{
    width: 25%;
    
}
.recordsAlign{
    display: contents;
}
img.mx-auto.d-block {
    margin-right: auto !important;
    margin-left: auto !important;
    width: auto;
}

/* textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
} */

img.img-check.icon {
    width: 50px;
    float: right;
}

.add-consultation-plus {
    margin-left: 6rem;
    color: #00979e;
}

textarea.doctorWritenPlace {
    width: 100%;
    height: 200px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 20px;
}

form.caseSheetTextAreas {
    width: 75%;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width:991px) {
    img.mx-auto.d-block {
        padding: 15px;
    }
}

@media screen and (max-width:769px) {
    form.caseSheetTextAreas {
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .dateButton {
        font-size: 15px;
    }
}
</style>
