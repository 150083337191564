<template>

  <fieldset class="curve-box-condition " v-if="selectedFoot === 'left'">
    <legend class="subHeadingText-condition">Left foot</legend>
    <div class="row">
      <div class="flex-row">
        <div class="source">
          <div class="row static-left-foot d-flex  justify-content-around">
            <div class="col-lg-1  "></div>

            <div v-for="(image, index) in staticImages" :key="index"
              class="col-lg-2 col-2 pb-2 bg-white border text-center"
              @click="!savedImageIndices.includes(index) && selectImage(image.src, image.label, index)"
              :class="{ 'disabled': savedImageIndices.includes(index), 'selected': selectedImageIndex === index }">
              <p class="text-center label-foot">{{ image.label }}</p>
              <img :src="image.src" />
            </div>

            <div class="col-lg-1 "></div>
          </div>

          <div>

          </div>
          <div class="row ">
            <div class="col-lg-1"></div>
            <div class="col-lg-10 col-12 p-0 ">
              <fieldset class="curve-box-condition">
                <legend class="subHeadingText-condition m-0">Condition</legend>
                <div class="row   ">
                  <div class="col-lg-2"></div>
                  <div class="col-lg-8 col-12">
                    <div class="condition-label ">
                      <label>
                        <input type="radio" v-model="color" value="#FF0000" /> Ulcer / Callus
                      </label>
                      <label>
                        <input type="radio" v-model="color" value="#0000FF" /> Deformity
                      </label>
                      <label>
                        <input type="radio" v-model="color" value="#008000" /> Pain
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-2"></div>
                </div>
              </fieldset>
            </div>
            <div class="col-lg-1"></div>


          </div>


          <div class="row my-2 choose " v-show="!canvasEnabled">
            <div class="col-lg-1"></div>
            <div class="col-lg-10 col-12 px-3  py-3" style="border: 2px solid #00979e; border-radius: 8px;">
              <p><b>Instructions</b></p>
              <p>Select the image of the desired view from the panel above.</p>
              <p>Choose the pathological condition to mark on image.</p>
              <p>Mark the location on the image using your finger or a stylus.</p>
              <p>Wish to edit a marked image? Select it from the panel below.</p>
            </div>
            <div class="col-lg-1"></div>
          </div>
          <div class="canvas-container border">
            <div class="text-center position-relative h-100 " v-show="canvasEnabled">
              <span class="cancel-icon" @click="cancelCanvas">&times;</span>
              <canvas ref="leftCanvas" class="canvas text-center " width="400%" height="300%" @mousedown="startDrawing"
                @mouseup="stopDrawing" @mousemove="draw" @touchstart="startDrawing" @touchend="stopDrawing"
                @touchmove="draw"></canvas>
            </div>
          </div>


          <canvas ref="hiddenCanvas" style="display: none;"></canvas>


          <div v-if="canvasEnabled" class="col-lg-12 justify-content-center d-flex mt-2">
            <div class=" col-lg-1"></div>
            <div class="col-6 col-lg-4">
              <div class="canvas-options d-flex align-items-center ">
              <button type="button" class="  btn btn-secondary btn-blue-color    mx-1" @click="undo"
                :disabled="undoStack.length === 0">Undo</button>
              <button type="button" class=" btn btn-secondary btn-blue-color mx-1" @click="redo"
                :disabled="redoStack.length === 0">Redo</button>
            </div>
            </div>

            <div class=" col-lg-2"></div>

           

<div class="col-6 col-lg-5 ">
  <button type="button" class="btn btn-blue-color text-white col-lg-8 col-12" @click.prevent="saveImage"
             >
              Save Image
            </button>
</div>
       
          </div>



          <div class="row saved-images" v-if="savedImages.length > 0">
            <hr class="mb-0 mt-1">
            <p class="text-center m-0 p-0 ">Saved images</p>
            <div class="d-flex justify-content-around">
              <div v-for="(image, index) in savedImages" :key="index" class="col-lg-2 col-2  border saved-image">
                <img :src="image.src" @click="editSavedImage(index)" />
                <p class="text-center label-foot">{{ image.label }}</p>
                <span class="cancel-icon-saved" @click.stop="deleteSavedImage(index)">&times;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import leftPlanter from "@/assets/images/L-planter.png";
import leftMedial from "@/assets/images/L-medial1.png";
import leftDorsum from "@/assets/images/L-dorsum1.png";
import leftLateral from "@/assets/images/L-Lateral1.png";

export default {
  data() {
    return {
      selectedFoot: "left",
      color: "transparent",
      line: 1,
      isDrawing: false,
      isSaved: false,
      undoStack: [],
      redoStack: [],
      canvasEnabled: false,
      savedImageIndices: [],
      currentEditIndex: null,
      context: null,
      selectedImageIndex: null,
      lastX: 0,
      lastY: 0,
      currentImageSrc: leftLateral,
      currentLabel: "Lateral",
      savedImages: [],
      staticImages: [
        { src: leftLateral, label: "Lateral" },
        { src: leftPlanter, label: "Plantar" },
        { src: leftDorsum, label: "Dorsum" },
        { src: leftMedial, label: "Medial" },
      ],
    };
  },
  mounted() {
    this.loadImageToCanvas(this.currentImageSrc);
    this.setupCanvasEvents();
  },
  methods: {

    saveState() {
      const canvas = this.$refs.leftCanvas;
      this.undoStack.push(canvas.toDataURL());
      if (this.undoStack.length > 10) this.undoStack.shift();
    },
    undo() {
      if (this.undoStack.length === 0) return;
      this.redoStack.push(this.undoStack.pop());
      this.restoreCanvas(this.undoStack[this.undoStack.length - 1]);
    },
    redo() {
      if (this.redoStack.length === 0) return;
      const redoImage = this.redoStack.pop();
      this.undoStack.push(redoImage);
      this.restoreCanvas(redoImage);
    },
    restoreCanvas(dataURL) {
      const canvas = this.$refs.leftCanvas;
      const context = canvas.getContext("2d");
      const img = new Image();
      img.src = dataURL;
      img.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    },
    cancelCanvas() {
      this.canvasEnabled = false;
      this.selectedImageIndex = null;
    },
    loadImageToCanvas(src) {
      const canvas = this.$refs.leftCanvas;
      this.context = canvas.getContext("2d");
      const img = new Image();
      img.src = src;
      img.onload = () => {
        this.context.clearRect(0, 0, canvas.width, canvas.height);
        this.context.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    },
    selectImage(src, label, index) {
      this.currentImageSrc = src;
      this.currentLabel = label;
      this.loadImageToCanvas(src);
      this.currentEditIndex = null;
      this.canvasEnabled = true;
      this.selectedImageIndex = index;
    },
    setupCanvasEvents() {
      const canvas = this.$refs.leftCanvas;
      canvas.addEventListener('mousedown', this.startDrawing);
      canvas.addEventListener('mouseup', this.stopDrawing);
      canvas.addEventListener('mousemove', this.draw);

      canvas.addEventListener('touchstart', this.startDrawing);
      canvas.addEventListener('touchend', this.stopDrawing);
      canvas.addEventListener('touchmove', this.draw);
    },
    startDrawing(event) {
      event.preventDefault();
      this.isDrawing = true;
      const rect = this.$refs.leftCanvas.getBoundingClientRect();
      const x = event.clientX || event.touches[0].clientX;
      const y = event.clientY || event.touches[0].clientY;
      this.lastX = x - rect.left;
      this.lastY = y - rect.top;

      this.saveState();
    },
    stopDrawing() {
      this.isDrawing = false;
      this.context.beginPath();
    },
    draw(event) {
      event.preventDefault();
      if (!this.isDrawing) return;
      const rect = this.$refs.leftCanvas.getBoundingClientRect();
      const x = event.clientX || event.touches[0].clientX;
      const y = event.clientY || event.touches[0].clientY;
      const currentX = x - rect.left;
      const currentY = y - rect.top;

      this.context.lineWidth = this.line;
      this.context.lineCap = "round";
      this.context.strokeStyle = this.color;

      this.context.beginPath();
      this.context.moveTo(this.lastX, this.lastY);
      this.context.lineTo(currentX, currentY);
      this.context.stroke();

      this.lastX = currentX;
      this.lastY = currentY;
    },
    saveImage() {
      this.color = ""
      const hiddenCanvas = this.$refs.hiddenCanvas;
      const canvas = this.$refs.leftCanvas;

      hiddenCanvas.width = canvas.width;
      hiddenCanvas.height = canvas.height;

      const hiddenContext = hiddenCanvas.getContext("2d");
      hiddenContext.drawImage(canvas, 0, 0);
      const savedImageSrc = hiddenCanvas.toDataURL("image/png");

      if (this.currentEditIndex !== null) {
        this.savedImages[this.currentEditIndex].src = savedImageSrc;
      } else {
        this.savedImages.push({
          src: savedImageSrc,
          label: this.currentLabel,
        });
        this.savedImageIndices.push(this.staticImages.findIndex(image => image.label === this.currentLabel));
        this.$emit('image-uploaded', this.savedImages);
      }

      this.isSaved = true;
      this.canvasEnabled = false;
      this.selectedImageIndex = null;
      setTimeout(() => {
        this.isSaved = false;
      }, 2000);
    },
    editSavedImage(index) {
      const savedImage = this.savedImages[index];
      this.loadImageToCanvas(savedImage.src);
      this.currentLabel = savedImage.label;
      this.currentEditIndex = index;
      this.canvasEnabled = true;
      this.selectedImageIndex = null;
    },
    deleteSavedImage(index) {
      this.savedImages.splice(index, 1);
      this.savedImageIndices.splice(index, 1);
    },
  },
};
</script>

<style>
.curve-box {
  margin-bottom: 20px;
}

.cancel-icon {
  position: absolute;
  top: 10px;
  right: 240px;
  color: #00979e !important;

  font-size: 24px;
  cursor: pointer;
}

.cancel-icon-saved {
  position: absolute;
  top: 3px;
  right: 10px;
  color: #00979e !important;

  font-size: 18px;
  cursor: pointer;
}



.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.canvas {
  border: 1px solid black;
  padding-top: 17px;
}

.saved-image {
  position: relative;
  height: 86px;
  width: 75px;
}

.saved-image img {
  width: 100%;
  cursor: pointer;
}

.saved-image .cancel-icon {
  position: absolute;
  top: 0;
  right: 10px;
  color: red;
  font-size: 16px;
  cursor: pointer;
}



.label-foot {
  margin-bottom: 0;
}

.selected {
  border: 2px solid #00979e !important;
  border-radius: 5px !important;
}

fieldset.curve-box-condition {
  border: 2px solid rgb(201, 197, 197);
  border-radius: 5px;
  padding: 0px 15px 15px 15px;
}

fieldset.sharp-box {
  border: 2px solid #cbcfcf;
  padding: 15px;
  margin-top: 1.5rem;
}

legend.subHeadingText {
  font-size: 18px !important;
}

legend.subHeadingText-condition {
  padding: 0px 10px;

  display: block;
  float: none;
  text-align: center;
  width: auto;
  font-size: 18px;
}

.condition-label {
  font-size: 16px;
  display: flex;
  justify-content: space-between;

}

.canvas:hover {
  cursor: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16"%3E%3Cpath d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/%3E%3C/svg%3E'), crosshair;
}


@media (max-width: 576px) {
  .source {
    max-width: 100%;
  }

  .canvas-container {
    max-width: 100%;
    margin-top: 10px;
    overflow: hidden;
    border: 1px solid black;

  }

  fieldset.curve-box-condition {
    border: 2px solid rgb(201, 197, 197);
    border-radius: 5px;
    padding: 0px 15px 3px 15px;
  }

  .label-foot {
    font-size: 12px !important;
  }

  .condition-label {
    font-size: 17px;
    display: flex;
    justify-content: space-between;

  }

  .canvas {
    padding-top: 2px;
    height: auto;
  }

  .cancel-icon {
    right: 10px !important;
    top: 1px !important;
  }

  .cancel-icon-saved {
    right: 1px !important;
    top: 1px !important;
  }

  .canvas-heading {
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  .subHeadingText,
  .subHeadingText-condition {
    font-size: 1rem;
  }

  .choose p {
    font-size: 18px;
    margin: 5px 0;
  }

  .static-images {
    width: 75px !important;
  }

  .saved-image img {
    width: 80px;
    margin-top: 4px;
    height: 58px;
  }
}

@media (max-width: 768px) {
  .source {
    max-width: 100%;
  }

  .canvas {
    height: auto;
    border: none
  }
}
</style>
